export const TransactionsUtil = [
  {
    id: 112,
    createdat: "2020-05-03 08:14:01",
    RoundID: "2752217563",
    fromwallet: "cruz@example.com",
    fromcurrency: "PHP",
    beforebalance: "Agent",
    afterbalance: "Active",
    towallet: "No",
    conversionrate: "No",
    action: "Win",
    actionby: "angupta@grepruby.io",
  },
  {
    id: 112,
    createdat: "2020-05-03 08:14:01",
    RoundID: "2752217563",
    fromwallet: "cruz@example.com",
    fromcurrency: "PHP",
    beforebalance: "Agent",
    afterbalance: "Active",
    towallet: "No",
    conversionrate: "No",
    action: "Win",
    actionby: "angupta@grepruby.io",
  },
  {
    id: 112,
    createdat: "2020-05-03 08:14:01",
    RoundID: "2752217563",
    fromwallet: "cruz@example.com",
    fromcurrency: "PHP",
    beforebalance: "Agent",
    afterbalance: "Active",
    towallet: "No",
    conversionrate: "No",
    action: "Win",
    actionby: "angupta@grepruby.io",
  },
  {
    id: 112,
    createdat: "2020-05-03 08:14:01",
    RoundID: "2752217563",
    fromwallet: "cruz@example.com",
    fromcurrency: "PHP",
    beforebalance: "Agent",
    afterbalance: "Active",
    towallet: "No",
    conversionrate: "No",
    action: "Win",
    actionby: "angupta@grepruby.io",
  },
  {
    id: 112,
    createdat: "2020-05-03 08:14:01",
    RoundID: "2752217563",
    fromwallet: "cruz@example.com",
    fromcurrency: "PHP",
    beforebalance: "Agent",
    afterbalance: "Active",
    towallet: "No",
    conversionrate: "No",
    action: "Win",
    actionby: "angupta@grepruby.io",
  },
  {
    id: 112,
    createdat: "2020-05-03 08:14:01",
    RoundID: "2752217563",
    fromwallet: "cruz@example.com",
    fromcurrency: "PHP",
    beforebalance: "Agent",
    afterbalance: "Active",
    towallet: "No",
    conversionrate: "No",
    action: "Win",
    actionby: "angupta@grepruby.io",
  },
  {
    id: 112,
    createdat: "2020-05-03 08:14:01",
    RoundID: "2752217563",
    fromwallet: "cruz@example.com",
    fromcurrency: "PHP",
    beforebalance: "Agent",
    afterbalance: "Active",
    towallet: "No",
    conversionrate: "No",
    action: "Win",
    actionby: "angupta@grepruby.io",
  },
];
