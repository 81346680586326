export const QualitifedUtils = [
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Rank: "ML",
    Country: "Philippines",
    Profit: "E-money",
    Share: "New",
    status: {
      color: "#F0AD4E",
      text: "Pending",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Rank: "ML",
    Country: "Philippines",
    Profit: "E-money",
    Share: "New",
    status: {
      color: "#01A601",
      text: "Awarded",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Rank: "ML",
    Country: "Philippines",
    Profit: "E-money",
    Share: "New",
    status: {
      color: "#01A601",
      text: "Awarded",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Rank: "ML",
    Country: "Philippines",
    Profit: "E-money",
    Share: "New",
    status: {
      color: "#01A601",
      text: "Awarded",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Rank: "ML",
    Country: "Philippines",
    Profit: "E-money",
    Share: "New",
    status: {
      color: "#01A601",
      text: "Awarded",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Rank: "ML",
    Country: "Philippines",
    Profit: "E-money",
    Share: "New",
    status: {
      color: "#F0AD4E",
      text: "Pending",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Rank: "ML",
    Country: "Philippines",
    Profit: "E-money",
    Share: "New",
    status: {
      color: "#F0AD4E",
      text: "Pending",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Rank: "ML",
    Country: "Philippines",
    Profit: "E-money",
    Share: "New",
    status: {
      color: "#F0AD4E",
      text: "Pending",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Rank: "ML",
    Country: "Philippines",
    Profit: "E-money",
    Share: "New",
    status: {
      color: "#F0AD4E",
      text: "Pending",
    },
  },
];
