import { IconButton } from "@material-tailwind/react";

export const Iconbutton = ({ children, className, onclick }) => {
  return (
    <IconButton className={className} onClick={onclick}>
      {" "}
      {children}
    </IconButton>
  );
};
