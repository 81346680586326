import React from "react";

export const CancelSubscription = ({ setactive, title, text, setAction }) => {
    return (
        <div className="delete-popup">
            <span
                className="bg-popup"
                onClick={(e) => {
                    setactive(false);
                }}
            ></span>
            <div className="flex flex-col rounded-lg py-10 px-20 items-center justify-center">
                <h1 className="mb-3">{title ?? "Are you sure?"}</h1>
                <h2 className="text-lg font-arabicmed text-white">
                    Are you sure you want to cancel the pending payment? If you cancel now.
                </h2>
                <p className="text-[20px] mb-10 mt-5 text-left font-arabicreg text-[#AEB2B1]">
                    - User subscription will not be activated.
                    <br />
                    - No charges will be applied.
                    <br />- If user pay using the provided payment instructions for this subscription, it will be ignored by the system.
                </p>
                <div className="buttons-wrapper gap-5 flex items-center justify-center">
                    <button
                        className="px-5 py-2"
                        onClick={(e) => {
                            setAction(false);
                        }}
                    >
                        {" "}
                        Proceed
                    </button>
                    <button
                        className="px-5 py-2 del"
                        onClick={(e) => {
                            setactive(false);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};
