import ButtonLoader from "./components/ButtonLoader";
import { useAuth } from "./contexts/AuthContext";

const PrivateRoute = ({ children }) => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="fixed left-0 top-0 flex items-center justify-center h-screen w-full bg-black">
        <ButtonLoader />
      </div>
    );
  }

  if (!user) {
    window.location.href = "/login";
    return null;
  }

  return children;
};

export default PrivateRoute;
