export const UserConfigUtils = [
  {
    id: 112,
    name: "Last Name",
  },
  {
    id: 112,
    name: "Email",
  },
  {
    id: 112,
    name: "Phone",
  },
  {
    id: 112,
    name: "Username",
  },
  {
    id: 112,
    name: "Nick Name",
  },
  {
    id: 112,
    name: "Currency",
  },
  {
    id: 112,
    name: "Confirm Password",
  },
  {
    id: 112,
    name: "Eighteen Year Check",
  },
];
