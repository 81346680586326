import React, { useEffect, useState } from "react";

// images

import "react-toastify/dist/ReactToastify.css";
import eye from "../../assets/img/auth/eye.svg";

// stylesheet
import "../../assets/css/admin/signin.css";
import "../../assets/css/sign-in.css";

// components
import logo from "../../assets/img/admin/svg/logo.svg";
import { useMutation } from "react-query";
import { publicRequest } from "../../config/axios.config";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";
import ButtonLoader from "../../components/ButtonLoader";
export const Signin = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const [type, setType] = useState(true);
  const [form, setForm] = useState({
    username: "",
    password: "",
  });

  const changeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const loginMutation = useMutation(
    (payload) => publicRequest.post("admin/auth/login", payload),
    {
      onSuccess: (res) => {
        // console.log(res.data, "res");
        toast.success(res.data.message);
        localStorage.setItem("token", res.data.data.token);
        window.location.href = "/";
      },
      onError: (error) => {
        console.log(error);
        toast.error(error.response.data?.message ?? "An error occurred");
      },
    }
  );

  const submitHandler = (e) => {
    e.preventDefault();
    loginMutation.mutate(form);
  };

  if (isLoading) {
    <div className="fixed left-0 top-0 flex items-center justify-center h-screen w-full bg-black">
      <ButtonLoader />
    </div>;
  }

  if (isAuthenticated) {
    return (window.location.href = "/");
  }

  return (
    <div className="SignIn LayerUp">
      <div className="inner-work-sign-in flex items-center justify-center w-[597px]">
        <div className="right-side w-[444px] py-10">
          <form onSubmit={submitHandler} className="flex flex-col h-full">
            <img src={logo} alt="" className="w-[280px] mx-auto mb-8" />
            <h1 className="mb-2">Sign in</h1>
            <p className="text-[14px] text-white font-normal text-center">
              Sign in to start your session
            </p>
            <div className="input-wrapper mt-10">
              <label htmlFor="email">Username</label>
              <input
                type="text"
                placeholder="Type your username here"
                className="rounded-lg px-6 mt-3"
                name="username"
                onChange={changeHandler}
                value={form.username}
              />
            </div>
            <div className="input-wrapper mt-5 mb-6">
              <label htmlFor="password">Login Password</label>
              <input
                type={type ? "password" : type}
                placeholder="Enter your password"
                className="rounded-lg px-6 mt-3"
                name="password"
                onChange={changeHandler}
                value={form.password}
              />
              <img
                src={eye}
                className="eye-icon"
                onClick={(e) => {
                  setType(!type);
                }}
                alt="eye icon"
              />
            </div>
            <button className="w-full rounded-lg mt-4 mb-8 "
            
            >
              {loginMutation.isLoading ? "Loading..." : "Sign In"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
