import React from "react";

export const ActivityBox = ({ title, data, type, total }) => {
  return (
    <div className="box-activity rounded-xl relative h-[220px]">
      <div className="top-box px-6 py-5 pb-3">
        <h1>{title}</h1>
      </div>
      <div className="content-area px-4 mb-3 mt-2">
        {type === "staking" && (
          <>
            <div className="activity-box-content-row px-3 flex justify-between items-center">
              <p>Active</p>
              <p>${data?.active ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex  justify-between items-center">
              <p>Expired</p>
              <p>${data?.expired ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex  justify-between items-center">
              <p>Terminated</p>
              <p>${data?.terminated ?? "0.00"}</p>
            </div>
          </>
        )}

        {type === "grr" && (
          <>
            <div className="activity-box-content-row px-3 flex justify-between items-center">
              <p>Pending</p>
              <p>${data?.pending ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex  justify-between items-center">
              <p>Confirmed</p>
              <p>${data?.confirmed ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex  justify-between items-center">
              <p>Processing</p>
              <p>${data?.processing ?? "0.00"}</p>
            </div>
          </>
        )}

        {type === "withdrawal" && (
          <>
            <div className="activity-box-content-row px-3 flex justify-between items-center">
              <p>Pending</p>
              <p>{data?.pending ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex justify-between items-center">
              <p>Processing</p>
              <p>{data?.processing ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex  justify-between items-center">
              <p>Confirmed</p>
              <p>{data?.confirmed ?? "0.00"}</p>
            </div>
          </>
        )}

        {type === "withdrawal_fee" && (
          <>
            <div className="activity-box-content-row px-3 flex justify-between items-center">
              <p>Pending</p>
              <p>${data?.pending ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex justify-between items-center">
              <p>Processing</p>
              <p>${data?.processing ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex  justify-between items-center">
              <p>Confirmed</p>
              <p>${data?.confirmed ?? "0.00"}</p>
            </div>
          </>
        )}

        {type === "royalty" && (
          <>
            <div className="activity-box-content-row px-3 flex justify-between items-center">
              <p>Pending</p>
              <p>${data?.pending ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex justify-between items-center">
              <p>Processing</p>
              <p>${data?.processing ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex  justify-between items-center">
              <p>Confirmed</p>
              <p>${data?.completed ?? "0.00"}</p>
            </div>
          </>
        )}

        {type === "commission_earned" && (
          <>
            <div className="activity-box-content-row px-3 flex justify-between items-center">
              <p>Pending</p>
              <p>${data?.pending ?? "0.00"}</p>
            </div>
            <div className="activity-box-content-row px-3 flex  justify-between items-center">
              <p>Confirmed</p>
              <p>${data?.confirmed ?? "0.00"}</p>
            </div>
          </>
        )}
      </div>
      <div className="bottom-box rounded-bl-xl rounded-br-xl h-11 px-4 flex items-center justify-between absolute bottom-0 w-full ">
        <h1>Total</h1>
        {type === "staking" && (
          <>
            <p>${data?.active ?? "0.00"}</p>
          </>
        )}
        {type === "grr" && (
          <>
            <p>${total ?? "0.00"}</p>
          </>
        )}
        {type === "withdrawal" && (
          <>
            <p>${total ?? "0.00"}</p>
          </>
        )}
        {type === "withdrawal_fee" && (
          <>
            <p>${total ?? "0.00"}</p>
          </>
        )}
        {type === "royalty" && (
          <>
            <p>${total ?? "0.00"}</p>
          </>
        )}
        {type === "commission_earned" && (
          <>
            <p>${total ?? "0.00"}</p>
          </>
        )}
      </div>
    </div>
  );
};
