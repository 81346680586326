import React, { useState } from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
// utils
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { privateRequest } from "../../config/axios.config";

export const EditSubAdmin = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    username: "",
    role: "",
  });

  const roles = useQuery("adminRoles", async () => {
    const res = await privateRequest.get("admin/roles/roles");
    return res.data;
  });

  const getSubAdminDetails = useQuery("subAdminDetails", async () => {
    const res = await privateRequest.get("admin/roles/roles");
    return res.data;
  });


  // regiser subadmin
  const registerSubAdmin = async (e) => {
    e.preventDefault();

    try {
      const res = await privateRequest.post("admin/auth/register", form);
      toast.success("Sub admin register successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div>
      <div className="flex items-start home admin-dashboard">
        {/* left area */}
        <Aside active={3} />

        <div className="flex-1">
          <Header heading="Sub admins" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Sub admin</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/");
                  }}
                >
                  Home
                </span>{" "}
                /
                <span
                  className="mr-1"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/admin/sub-admin");
                  }}
                >
                  Sub admin
                </span>{" "}
                / Create
              </button>
            </div>

            <div className="agent-wrapper create-agents mt-8  rounded-xl">
              <div className="top-agent  rounded-tl-xl  rounded-tr-xl py-7 px-6 top-agent-page flex items-center justify-between">
                <h1>Create Sub Admin</h1>
              </div>
              <div className="forms-wrapper px-6 py-6">
                <form
                  onSubmit={registerSubAdmin}
                  className="grid grid-cols-2 gap-x-3 gap-y-6"
                >
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Name <span>*</span>
                    </label>
                    <div className="input-container  mt-3">
                      <input
                        value={form?.name}
                        onChange={(e) =>
                          setForm((prevForm) => ({
                            ...prevForm,
                            name: e.target.value,
                          }))
                        }
                        type="text"
                        placeholder="Enter name"
                      />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Email <span>*</span>
                    </label>
                    <div className="input-container  mt-3">
                      <input
                        value={form?.email}
                        onChange={(e) =>
                          setForm((prevForm) => ({
                            ...prevForm,
                            email: e.target.value,
                          }))
                        }
                        type="email"
                        placeholder="Enter email"
                      />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Username <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input
                        value={form?.username}
                        onChange={(e) =>
                          setForm((prevForm) => ({
                            ...prevForm,
                            username: e.target.value,
                          }))
                        }
                        type="text"
                        placeholder="Enter username"
                      />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Password <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input
                        value={form?.password}
                        onChange={(e) =>
                          setForm((prevForm) => ({
                            ...prevForm,
                            password: e.target.value,
                          }))
                        }
                        type="pasword"
                        placeholder="Enter Password"
                      />
                    </div>
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="">
                      Roles <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <select
                        onChange={(e) =>
                          setForm((prevForm) => ({
                            ...prevForm,
                            role: e.target.value,
                          }))
                        }
                      >
                        <option value="">Select Role</option>
                        <option value="subadmin">Subadmin</option>
                        {roles?.data?.data?.map((role) => (
                          <option key={role?._id} value={role?.name}>
                            {role?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="input-wrapper  flex items-center justify-between col-span-2">
                    <div className="flex buttons-wrapper items-center">
                      <button className="h-11 px-4 rounded mr-3">
                        Update Sub Admin
                      </button>
                      <button className="h-11 px-4 rounded outline-button">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
