export const LeadersUtils = [
  {
    id: "111",
    email: "examplea@gamil.com",
    Date: "28-05-2023",
    Currency: "BTC",
    Amount: "100.00",
    Descrpt: "New",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
  {
    id: "111",
    email: "examplea@gamil.com",
    Date: "28-05-2023",
    Currency: "BTC",
    Amount: "100.00",
    Descrpt: "New",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
  {
    id: "111",
    email: "examplea@gamil.com",
    Date: "28-05-2023",
    Currency: "BTC",
    Amount: "100.00",
    Descrpt: "New",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
  {
    id: "111",
    email: "examplea@gamil.com",
    Date: "28-05-2023",
    Currency: "BTC",
    Amount: "100.00",
    Descrpt: "New",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
  {
    id: "111",
    email: "examplea@gamil.com",
    Date: "28-05-2023",
    Currency: "BTC",
    Amount: "100.00",
    Descrpt: "New",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
];
