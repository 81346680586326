export const NotificationUtil = [
  {
    id: 112,
    message: "rsadhu created withdraw request",
    received: "Jul 7, 2023 4:39",
    link: "https://www.google.com/",
  },
  {
    id: 112,
    message: "rsadhu created withdraw request",
    received: "Jul 7, 2023 4:39",
    link: "https://www.google.com/",
  },
  {
    id: 112,
    message: "rsadhu created withdraw request",
    received: "Jul 7, 2023 4:39",
    link: "https://www.google.com/",
  },
  {
    id: 112,
    message: "rsadhu created withdraw request",
    received: "Jul 7, 2023 4:39",
    link: "https://www.google.com/",
  },
  {
    id: 112,
    message: "rsadhu created withdraw request",
    received: "Jul 7, 2023 4:39",
    link: "https://www.google.com/",
  },
  {
    id: 112,
    message: "rsadhu created withdraw request",
    received: "Jul 7, 2023 4:39",
    link: "https://www.google.com/",
  },
  {
    id: 112,
    message: "rsadhu created withdraw request",
    received: "Jul 7, 2023 4:39",
    link: "https://www.google.com/",
  },
  {
    id: 112,
    message: "rsadhu created withdraw request",
    received: "Jul 7, 2023 4:39",
    link: "https://www.google.com/",
  },
  {
    id: 112,
    message: "rsadhu created withdraw request",
    received: "Jul 7, 2023 4:39",
    link: "https://www.google.com/",
  },
  {
    id: 112,
    message: "rsadhu created withdraw request",
    received: "Jul 7, 2023 4:39",
    link: "https://www.google.com/",
  },
  {
    id: 112,
    message: "rsadhu created withdraw request",
    received: "Jul 7, 2023 4:39",
    link: "https://www.google.com/",
  },
];
