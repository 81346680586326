import React from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";

// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
import arrowdown from "../../assets/img/admin/svg/arrow-down.svg";

// utils
import { useNavigate } from "react-router-dom";
import { PendingCryptoWithdrawalTable } from "../../components/admin/PendingCryptoWithdrawalTable";
import { useGetCryptoWithdrawals } from "../../queries/withdraw";

export const CryptoWithdrawals = () => {
    const navigate = useNavigate();

    const [search, setSearch] = React.useState("");
    const [status, setStatus] = React.useState("pending");

    const { data, refetch } = useGetCryptoWithdrawals(status, search);

    const resetFilter = () => {
        setSearch("");
        setStatus("pending");
    };

    const withdrawals = data?.data?.data;

    return (
        <div>
            <div className="flex items-start home admin-dashboard admin-dashboard-agent">
                {/* left area */}
                <Aside active="8/2" />

                <div className="flex-1">
                    <Header heading="Crypto Withdrawal" />

                    <div className="body-area-admin py-8 px-6">
                        <div className="top-body-area flex items-center justify-between">
                            <h1>Crypto Withdrawal</h1>
                            <button className="flex items-center">
                                <img src={arrowback} alt="" className="mr-2" />
                                <span
                                    className="mr-1"
                                    onClick={(e) => {
                                        navigate("/");
                                    }}
                                >
                                    Home
                                </span>{" "}
                                {/* / Withdrawal */}
                            </button>
                        </div>
                        <div className="mt-8 p-6 pt-8 rounded-xl bg-[#1a1a1b] text-white">
                            <div className="flex flex-row lg:flex-col justify-start gap-5">
                                <div className="box p-5 rounded-lg border border-[#545454]">
                                    <h4 className="text-2xl text-[#6FCF17]">
                                        {" "}
                                        ${data?.data?.statistics?.confirmed?.totalAmount}{" "}
                                    </h4>
                                    <h4 className="text-md">
                                        {" "}
                                        Confirmed ( {data?.data?.statistics?.confirmed?.count} )
                                    </h4>
                                </div>
                                <div className="box p-5 rounded-lg border border-[#545454]">
                                    <h4 className="text-2xl text-[#6FCF17]">
                                        {" "}
                                        ${data?.data?.statistics?.processing?.totalAmount}
                                    </h4>
                                    <h4 className="text-md">
                                        Processing ( {data?.data?.statistics?.processing?.count} )
                                    </h4>
                                </div>
                                <div className="box p-5 rounded-lg border border-[#545454]">
                                    <h4 className="text-2xl text-[#6FCF17]">
                                        {" "}
                                        ${data?.data?.statistics?.pending?.totalAmount}
                                    </h4>
                                    <h4 className="text-md">
                                        Pending ( {data?.data?.statistics?.pending?.count} )
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
                            <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                                <h1>Withdrawal</h1>

                                {/* <div className="flex items-center">
                  <div className="flex items-center mr-3">
                    <RadioCustom type="checkbox" id="Fiat" />
                    <p className="ml-2 checkbox-para">Fiat</p>
                  </div>
                  <div className="flex items-center ">
                    <RadioCustom type="checkbox" id="Crypto" />
                    <p className="ml-2 checkbox-para">Crypto</p>
                  </div>
                </div> */}
                            </div>
                            <div className="bottom-agent bottom-agent-page  pt-6  pb-4  gap-2 flex items-end">
                                <div className="input-container flex-1">
                                    <label htmlFor="search" className="mb-3 block">
                                        Search
                                    </label>
                                    <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                                        <img src={search} alt="" className="mr-2" />
                                        <input
                                            value={search}
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                            }}
                                            id="search"
                                            type="text"
                                            placeholder="Search TXID, Amount, Currency, Wallet Address"
                                            className="flex-1 h-full bg-transparent border-0 outline-none "
                                        />
                                        <img src={arrowdown} alt="" />
                                    </div>
                                </div>

                                <div className="input-container w-48">
                                    <label htmlFor="status" className="mb-3 block">
                                        Status
                                    </label>
                                    <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                                        <select
                                            className="text-black"
                                            onChange={(e) => {
                                                setStatus(e.target.value);
                                            }}
                                            value={status}
                                            name="status"
                                        >
                                            <option value="">All</option>
                                            <option value="pending">Pending</option>
                                            <option value="processing">Processing</option>
                                            <option value="confirmed">Confirmed</option>
                                            <option value="declined">Declined</option>
                                        </select>
                                    </div>
                                </div>


                                <button
                                    className="rounded-lg px-5 filter"
                                    onClick={resetFilter}
                                >
                                    Reset Filter
                                </button>
                            </div>
                            <div className="table-wrapper">
                                <PendingCryptoWithdrawalTable refetch={refetch} data={withdrawals} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};
