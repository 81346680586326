import React, { useEffect, useState } from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";

// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";

// utils
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert } from "../../components/admin/Alert";
import { privateRequest } from "../../config/axios.config";

export const WithdrawalDetails = () => {
  const [transactionId, setTransactionId] = useState(null);
  const [delpopup, setdelpopup] = useState(false);
  const [transaction, setTransaction] = useState("");

  const querClient = useQueryClient();
  const params = useParams();
  const navigate = useNavigate();
  const transactions = async () => {
    const res = await privateRequest.get(
      `admin/withdrawal/withdrawal-details?transactionId=${params?.id}`
    );
    setTransaction(res?.data);
  };

  useEffect(() => {
    transactions();
  }, []);

  const approveWithdrawal = useMutation((id) =>
    privateRequest.get(
      `admin/withdrawal/approved-withdrawal-request?requestId=${transactionId}`
    )
  );

  const handelApproval = (id) => {
    setdelpopup(true);
    setTransactionId(id);
  };

  const submitAction = async () => {
    try {
      toast.promise(approveWithdrawal.mutateAsync(), {
        pending: "Approving withdrawal...",
        success: () => {
          querClient.invalidateQueries("withdrawals");
          transactions();
          return "Withdrawal approved successfully!";
        },
        error: "Error approving withdrawal",
      });

    } catch (error) {
      console.log(error);
    }
  };

  console.log(transaction);

  return (
    <>
      <div>
        {delpopup && (
          <Alert
            setactive={() => setdelpopup(false)}
            confirmSubadminBlock={(value) => {
              if (value) {
                submitAction();
                setdelpopup(false);
              }
            }}
            title="Are you sure to approve the withdrawal request"
            buttonText="Approve"
          />
        )}
        <div className="flex items-start home admin-dashboard admin-dashboard-agent">
          {/* left area */}
          <Aside active="8/2" />

          <div className="flex-1">
            <Header heading="Withdrawal" />

            <div className="body-area-admin py-8 px-6">
              <div className="top-body-area flex items-center justify-between">
                <h1>Withdrawal Details</h1>
                <button className="flex items-center">
                  <img src={arrowback} alt="" className="mr-2" />
                  <span
                    className="mr-1"
                    onClick={(e) => {
                      navigate("/");
                    }}
                  >
                    Home
                  </span>{" "}
                  {/* / Withdrawal Details */}
                </button>
              </div>

              <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
                <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                  <h1>Withdrawal Details</h1>
                </div>
                <div className=" text-white py-5 space-y-4">
                  <div className="flex justify-start border-b gap-2 border-gray-900 pb-2">
                    <div className="w-44">Reference</div>
                    <div>:</div>
                    <div>
                      {transaction?.transaction?.transactionReference}
                    </div>
                  </div>
                  <div className="flex justify-start gap-2 border-b border-gray-900 pb-2">
                    <div className="w-44">Email</div>
                    <div>:</div>
                    <div >
                      {transaction?.transaction?.userId?.email}
                    </div>
                  </div>
                  <div className="flex justify-start gap-2 border-b border-gray-900 pb-2">
                    <div className="w-44">Amount</div>
                    <div>:</div>
                    <div >
                      {transaction?.transaction?.amount}{" "}
                      {transaction?.transaction?.currency.toUpperCase()}
                    </div>
                  </div>
                  <div className="flex justify-start gap-2 border-b border-gray-900 pb-2">
                    <div className="w-44">Status</div>
                    <div>:</div>
                    <div >
                      {transaction?.transaction?.status}
                    </div>
                  </div>
                  <h3 className="text-[#6FCF17] text-lg my-5">Payment Information</h3>
                  {/* <div className="flex justify-start gap-2 border-b border-gray-900 pb-2">
                    <div className="w-44">Network</div>
                    <div>:</div>
                    <div >
                      {transaction?.transaction?.meta?.network}
                    </div>
                  </div> */}
                  <div className="flex justify-start gap-2 border-b border-gray-900 pb-2">
                    <div className="w-44">Payment Currency</div>
                    <div>:</div>
                    <div >
                      {transaction?.transaction?.meta?.paymentCurrency.toUpperCase()}
                    </div>
                  </div>
                  <div className="flex justify-start gap-2 border-b border-gray-900 pb-2">
                    <div className="w-44">Payment Method</div>
                    <div>:</div>
                    <div >
                      {transaction?.transaction?.meta?.paymentMethod.toUpperCase()}
                    </div>
                  </div>
                  <div className="flex justify-start gap-2 border-b border-gray-900 pb-2">
                    <div className="w-44">Amount in Payment Currency</div>
                    <div>:</div>
                    <div >
                      {parseFloat(transaction?.transaction?.meta?.paymentCurrencyAmount).toFixed(2)}   {transaction?.transaction?.meta?.paymentCurrency.toUpperCase()}
                    </div>
                  </div>
                  {transaction?.transaction?.meta?.walletAddress && <>
                    <div className="flex justify-start gap-2 border-b border-gray-900 pb-2">
                      <div className="w-44">Wallet Address</div>
                      <div>:</div>
                      <div >
                        {transaction?.transaction?.meta?.walletAddress}
                      </div>
                    </div>
                  </>}
                  <div className="flex justify-start gap-2 border-b border-gray-900 pb-2">
                    <div className="w-44">Ref No.</div>
                    <div>:</div>
                    <div >
                      {transaction?.transaction?.meta?.refno}
                    </div>
                  </div>
                  <div className="flex justify-start gap-2 border-b border-gray-900 pb-2">
                    <div className="w-44">Name</div>
                    <div>:</div>
                    <div >
                      {transaction?.transaction?.meta?.name}
                    </div>
                  </div>
                  <div className="flex justify-start gap-2 border-b border-gray-900 pb-2">
                    <div className="w-44">Mobile No.</div>
                    <div>:</div>
                    <div >
                      {transaction?.transaction?.meta?.cpno}
                    </div>
                  </div>
                </div>
                {transaction?.transaction?.status === "pending" && (
                  <>
                    <button
                      onClick={() =>
                        handelApproval(transaction?.transaction?._id)
                      }
                      className="mr-1 bg-[#6FCF17] text-white px-3 py-1 rounded-sm font-medium"
                    >
                      Approve Now
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
