import React from "react";

// images
import button from "../../assets/img/auth/google.svg";
import button1 from "../../assets/img/auth/facebook.svg";
import button2 from "../../assets/img/auth/apple.svg";
import button3 from "../../assets/img/auth/twitter.svg";
import button4 from "../../assets/img/auth/send.svg";
import button5 from "../../assets/img/auth/whatsapp.svg";
import logo from "../../assets/img/admin/svg/logo.svg";
// component
import { Link } from "react-router-dom";
export const ForgetPassword = () => {
  return (
    <div className="SignIn LayerUp">
      <div className="inner-work-sign-in flex items-center justify-center w-[597px]">
        <div className="right-side  w-[444px] py-10">
          <form className="flex flex-col h-full">
            <img src={logo} alt="" className="w-[280px] mx-auto mb-8" />
            <h1 className="mb-2">Reset Password</h1>
            <p className="text-[14px] text-white font-normal text-center">
              Reset your password and sign in again!
            </p>
            <div className="input-wrapper mt-10 mb-6">
              <label htmlFor="email">Email / Phone Number</label>
              <input type="email" id="email" className="rounded-lg px-6 mt-3" />
            </div>

            <button type="submit" className="w-full relative rounded-lg mb-9">
              Reset Password
            </button>
            <p className="dont-have-p flex justify-center">
              Don’t have an account!
              <Link to="/signup" className="ml-1">
                Sign up
              </Link>
            </p>

            <div className="ending-point  flex-1 flex flex-col  justify-end">
              <div className="line-breaker flex items-center mt-8">
                <span className="flex-1 mr-2"></span>
                <p>Or Login with</p>
                <span className="flex-1 ml-2"></span>
              </div>

              <div className="mt-7 social-icons flex items-center justify-between">
                <a href="/">
                  <img src={button} alt="social button" />
                </a>
                <a href="/">
                  <img src={button1} alt="social button" />
                </a>
                <a href="/">
                  <img src={button2} alt="social button" />
                </a>
                <a href="/">
                  <img src={button3} alt="social button" />
                </a>
                <a href="/">
                  <img src={button4} alt="social button" />
                </a>
                <a href="/">
                  <img src={button5} alt="social button" />
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
