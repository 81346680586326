import React, { useEffect } from "react";
import notification from "../../assets/img/notification.svg";
import { Iconbutton } from "../Iconbutton";
import logoutOut from "../../assets/img/admin/svg/logout-out.svg";
import { useNavigate } from "react-router-dom";

export const Header = ({ heading }) => {
  const navigate = useNavigate();

  return (
    <header className="px-6 h-16 flex items-center">
      <h1>{heading}</h1>

      <div className="right-header-area items-center flex flex-1 justify-end">
        <div className="icon-buttons flex items-center">
          <Iconbutton
            className="mr-3 w-8 h-8 icon-btn"
            onclick={(e) => {
              navigate("/admin/notifications");
            }}
          >
            <img src={notification} alt="notification" />
          </Iconbutton>

          <button
            className="flex logout-btn items-center px-3 h-8 rounded-lg"
            onClick={(e) => {
              localStorage.removeItem("token");
              window.location.href = "/login";
            }}
          >
            <img src={logoutOut} alt="" className="mr-1" />
            Log out
          </button>
        </div>
      </div>
      <div
        className="burger-icon hidden ml-2"
        onClick={(e) => {
          document
            .querySelector(".admin-dashboard > *:first-child")
            .classList.toggle("active");
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#fff"
          class="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </header>
  );
};
