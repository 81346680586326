import React, { useState } from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { useNavigate } from "react-router-dom";
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";

// utils
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import ButtonLoader from "../../components/ButtonLoader";
import { TransferDone } from "../../components/admin/TransferDone";
import { privateRequest } from "../../config/axios.config";
export const Transfer = () => {
  const [transfer, setTransfer] = useState(false);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: '',
    amount: '',
    note: ''
  });
  const resetForm = () => {
    setForm({
      email: '',
      amount: '',
      note: '',
    });
  };


  const { mutate: submitTransferBalance, isLoading } = useMutation(
    (payload) => privateRequest.post("admin/wallet/transferBalance", payload),
    {
      onSuccess: () => {
        toast.success("Transfer successful");
        setTransfer(true);
      },
      onError: (error) => {
        if (error?.response.data?.message) {
          toast.error(error?.response.data?.message);
          return;
        }
        toast.error("Transfer failed");
      },
    }
  );

  const handleTransferBalance = async (e) => {
    e.preventDefault();
    setTransfer(true); // Set transfer to true when the button is clicked
    await submitTransferBalance(form); // Use await to wait for the API call
    setTransfer(false); // Set transfer back to false after the API call is completed
    // resetForm();
  };




  return (
    <div>
      <div className="flex items-start home admin-dashboard  admin-dashboard-agent">
        {/* left area */}
        <Aside active="wallet/1" />

        <div className="flex-1">
          <Header heading="Agents" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Wallets</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/");
                  }}
                >
                  Home
                </span>{" "}
                {/* / Transfer Balance */}
              </button>
            </div>

            <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
              <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                <h1>Transfer Balance</h1>
              </div>
              <div className="bottom-agent bottom-agent-page   pt-6    gap-2 flex items-end"></div>

              <div className="">
                <form onSubmit={handleTransferBalance} method="post">
                  <div className="mb-6">
                    <label
                      htmlFor="#"
                      className="text-[18px] font-arabicreg text-[#fff] mb-3 block"
                    >
                      User Email <span className="text-[#D71A1A]">*</span>
                    </label>
                    <input
                      placeholder="Enter email address"
                      type="text"
                      value={form?.email}
                      onChange={(e) =>
                        setForm((prevForm) => ({
                          ...prevForm,
                          email: e.target.value,
                        }))
                      }
                      className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-[#AEB2B1] text-[16px] font-arabicmed px-6 sm:text-[14px]"
                    />

                  </div>
                  <div className="mb-6">
                    <label
                      htmlFor="#"
                      className="text-[18px] font-arabicreg text-[#fff] mb-3 block"
                    >
                      Transfer Amount <span className="text-[#D71A1A]">*</span>
                    </label>
                    <input
                      placeholder="Enter your amount"
                      type="text"
                      value={form?.amount}
                      onChange={(e) =>
                        setForm((prevForm) => ({
                          ...prevForm,
                          amount: e.target.value,
                        }))
                      }
                      className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-[#AEB2B1] text-[16px] font-arabicmed px-6 sm:text-[14px]"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="#"
                      className="text-[18px] font-arabicreg text-[#fff] mb-3 block"
                    >
                      Transfer Note
                    </label>
                    <textarea
                      name=""
                      value={form?.note}
                      onChange={(e) =>
                        setForm((prevForm) => ({
                          ...prevForm,
                          note: e.target.value,
                        }))
                      }
                      placeholder="Enter your transfer note"
                      id=""
                      cols="30"
                      rows="10"
                      className="bg-[#111010] border-[1px] border-[#3C403F] w-full  rounded-lg outline-none text-white placeholder:text-[#AEB2B1] text-[18px] font-arabicmed px-6 py-4 h-[97px] sm:text-[14px] resize-none"
                    ></textarea>
                  </div>
                  <div className="grid grid-cols-2 gap-6  mt-12">
                    <button
                      type="submit"
                      // onClick={(e) => {
                      //   settransfer(true);
                      // }}
                      className="text-[14px] text-[#ffff] bg-[#6FCF17] h-[50px]
                           w-[100%] rounded font-bold flex justify-center items-center"
                    >
                      {isLoading ? <>
                        <div className="flex gap-3 items-center">
                          <ButtonLoader />
                          Transfering Balance
                        </div>

                      </> : <>
                        Transfer Proceed

                      </>}

                    </button>
                    <button
                      onClick={resetForm}
                      className="text-[14px] text-[#fff] border-[1px] border-[#6FCF17] h-[50px]
                           w-[100%] rounded font-bold"
                    >
                      Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {transfer && <TransferDone email={form?.email} settransfer={setTransfer} />}
      <Footer />
    </div>
  );
};
