import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";

const AuthContext = createContext(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const token = localStorage.getItem("token");
  const {
    data: userInfo,
    isError,
    isLoading,
  } = useQuery("userInfos", () => privateRequest.get("admin/dashboard/me"), {
    enabled: !!token,
    retry: false,
  });

  useEffect(() => {
    if (userInfo) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [userInfo]);

  const value = {
    isAuthenticated,
    user: userInfo?.data?.admin,
    isError,
    isLoading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
