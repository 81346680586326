import React, { useState } from "react";
import { toast } from "react-toastify";
import avatar from "../../assets/img/admin/svg/avatar-aside.svg";
import block from "../../assets/img/admin/svg/block.svg";
import unblock from "../../assets/img/admin/svg/unblock.svg";
import { Alert } from "../../components/admin/Alert";
import Pagination from "../../components/admin/Pagination";
import { UpdateRankModal } from "../../components/admin/UpdateRankModal";
import { privateRequest } from "../../config/axios.config";
export const MemberTable = ({ data, fetchData, totalPages, onPageChange }) => {
  const [activepagination, setactivepagination] = useState(1);
  const [delpopup, setdelpopup] = useState(false);
  const [actionType, setActionType] = useState(""); // "block" or "unblock"
  const [userId, setUserId] = useState(null);
  const [rankUpdate, setRankUpdate] = useState(false);
  const [userRank, setUserRank] = useState(null);
  const handleBlock = (id) => {
    setActionType("block");
    setUserId(id);
    setdelpopup(true);
  };

  const handleUnblock = (id) => {
    setActionType("unblock");
    setUserId(id);
    setdelpopup(true);
  };

  const handUserRankUpdate = (id) => {
    setRankUpdate(true);
    setUserId(id);
    console.log(id);
  };

  const submitAction = async () => {
    try {
      const endpoint =
        actionType === "block" ? "block-member" : "unblock-member";

      const res = await privateRequest.post(
        `admin/member-management/${endpoint}`,
        {
          userId: userId,
        }
      );

      toast.success(res?.data?.message);
      fetchData();
    } catch (error) {
      console.log(error);
    } finally {
      setUserId(null);
      setActionType("");
      setdelpopup(false);
    }
  };

  const handlePageChange = (page) => {
    setactivepagination(page);
    fetchData(page);
    onPageChange(page);
  };

  const updateUserRank = async () => {
    try {
      const payload = {
        userId: userId,
        rank: userRank,
      };

      const res = await privateRequest.post(
        "admin/member-management/update-rank",
        payload
      );
      toast.success(res?.data?.message);
      setRankUpdate(false);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {delpopup && (
        <Alert
          setactive={() => setdelpopup(false)}
          confirmSubadminBlock={(value) => {
            if (value) {
              submitAction();
            }
          }}
          title={
            actionType === "block"
              ? "Are you sure to block the user?"
              : "Are you sure to unblock the user?"
          }
          buttonText={actionType === "block" ? "Yes, Block" : "Yes, Unblock"}
        />
      )}

      {rankUpdate && (
        <UpdateRankModal
          setactive={() => setRankUpdate(false)}
          title="Update user rank"
          buttonText="Change Rank"
          selectedRank={(value) => {
            setUserRank(value);
          }}
          UserRankUpdate={updateUserRank}
        />
      )}

      <table className="w-full AgentTable">
        <thead>
          <th>ID</th>
          <th>Avatar</th>
          <th>User Code</th>
          <th>Email</th>
          <th>Rank</th>
          <th>Date joined</th>
          <th>Action</th>
        </thead>
        <tbody>
          {data?.map((member, index) => (
            <tr>
              <td>{index + 1}</td>
              <td className="py-2">
                {member?.avatar ? (
                  <>
                    <img
                      src={member.avatar}
                      className="w-16 h-16 rounded-full"
                    />
                  </>
                ) : (
                  <>
                    <img src={avatar} className="w-16 h-16 rounded-full" />
                  </>
                )}
              </td>
              <td>{member.userCode}</td>
              <td>{member.email}</td>
              <td>{member.rank}</td>
              <td>{member.createdAt}</td>
              <td>
                <div className="flex items-center">
                  {member.status === "blocked" ? (
                    <button
                      onClick={() => handleUnblock(member._id)}
                      className={`flex items-center justify-center gap-1 bg-[#FFD351]
                      text-[#111010] h-[22px] px-[10px] rounded-[2px] text-[12px] font-arabicreg ${
                        member.status !== "blocked" &&
                        "!bg-[#ffffff] !text-white"
                      }`}
                    >
                      <img src={block} alt="" />
                      {member.status === "blocked" ? "Unblocked" : "Block"}
                    </button>
                  ) : (
                    <button
                      onClick={() => handleBlock(member._id)}
                      className={`flex items-center justify-center gap-1 bg-[#FFD351]
                    text-[#111010] h-[22px] px-[10px] rounded-[2px] text-[12px] font-arabicreg ${
                      member.status !== "blocked" && "!bg-[#8A3FFC] !text-white"
                    }`}
                    >
                      <img
                        onClick={() => handleBlock(member._id)}
                        src={unblock}
                        alt=""
                      />
                      {member.status === "blocked" ? "Unblocked" : "Block"}
                    </button>
                  )}

                  <button
                    onClick={() => handUserRankUpdate(member._id)}
                    className={`flex ml-2 items-center justify-center gap-1 bg-[#FFD351]
                      text-[#111010] h-[22px] px-[10px] rounded-[2px] text-[12px] font-arabicreg ${
                        member.status !== "blocked" &&
                        "!bg-[#6FCF17] !text-white"
                      }`}
                  >
                    Update Rank
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        totalPages={totalPages} // Set the total number of pages
        currentPage={activepagination}
        onPageChange={handlePageChange}
      />
    </>
  );
};
