import { CheckboxCustom } from "./CheckboxCustom";
import deleteImg from "../../assets/img/admin/svg/delete.svg";
import edit from "../../assets/img/admin/svg/edit.svg";
import { Popup } from "./Popup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export const BankTable = ({ data }) => {
  const [delpopup, setdelpopup] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      {delpopup && <Popup setactive={setdelpopup} />}

      <table className="w-full AgentTable">
        <thead>
          <th>ID</th>
          <th>Bank Name</th>
          <th>IFSC Code</th>

          <th>Account Number</th>
          <th>Account Holder Name</th>
          <th>Last Updated By</th>

          <th>Active </th>
          <th>Created Date</th>
          <th>Updated Date</th>
          <th>Action by</th>
        </thead>
        <tbody>
          {data.map((EachData) => (
            <tr>
              <td>{EachData.id}</td>
              <td>{EachData.bankname}</td>
              <td>{EachData.ifscnumber}</td>

              <td>{EachData.accountname}</td>
              <td>{EachData.accountnumber}</td>
              <td>{EachData.lastupdate}</td>
              <td>{EachData.active}</td>
              <td>{EachData.created}</td>
              <td>{EachData.updated}</td>
              <td>
                <div className="flex items-center">
                  <button
                    className="mr-1"
                    onClick={(e) => {
                      navigate("/admin/payment/bank/create");
                    }}
                  >
                    <img src={edit} alt="" />
                  </button>
                  <button
                    onClick={(e) => {
                      setdelpopup(true);
                    }}
                  >
                    <img src={deleteImg} alt="" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex items-center mt-6">
        <div className="flex items-center">
          <CheckboxCustom type="checkbox" id="active-agree" />
          <p className="ml-2 checkbox-para">Crypto</p>
        </div>
      </div>
    </>
  );
};
