export const PlayerReportUtil = [
  {
    id: 112,
    agentname: "Imran Hossen",
    currency: "PHP",
    bet: "0.00",
    betphp: "0.00",
    win: "0.00",
    winphp: "0.00",
    ggr: "0.00",
    bonus: "0.00",
    bonusphp: "0.00",
    comission: "0.00",
    revenue: "0.00",
  },
];
