export const AgentsUtil = [
  {
    id: 112,
    email: "sara.cruz@example.com",
    adminname: "Gogozoom",
    fullname: "Miksa Fruzsina",
    phonenumber: "(+33)7 65 55 72 67",
    roles: "Agent",
    status: "Active",
    kyc: "No",
  },
  {
    id: 112,
    email: "sara.cruz@example.com",
    adminname: "Gogozoom",
    fullname: "Miksa Fruzsina",
    phonenumber: "(+33)7 65 55 72 67",
    roles: "Agent",
    status: "Active",
    kyc: "No",
  },
  {
    id: 112,
    email: "sara.cruz@example.com",
    adminname: "Gogozoom",
    fullname: "Miksa Fruzsina",
    phonenumber: "(+33)7 65 55 72 67",
    roles: "Agent",
    status: "Active",
    kyc: "No",
  },
  {
    id: 112,
    email: "sara.cruz@example.com",
    adminname: "Gogozoom",
    fullname: "Miksa Fruzsina",
    phonenumber: "(+33)7 65 55 72 67",
    roles: "Agent",
    status: "Active",
    kyc: "No",
  },
  {
    id: 112,
    email: "sara.cruz@example.com",
    adminname: "Gogozoom",
    fullname: "Miksa Fruzsina",
    phonenumber: "(+33)7 65 55 72 67",
    roles: "Agent",
    status: "Active",
    kyc: "No",
  },
  {
    id: 112,
    email: "sara.cruz@example.com",
    adminname: "Gogozoom",
    fullname: "Miksa Fruzsina",
    phonenumber: "(+33)7 65 55 72 67",
    roles: "Agent",
    status: "Active",
    kyc: "No",
  },
  {
    id: 112,
    email: "sara.cruz@example.com",
    adminname: "Gogozoom",
    fullname: "Miksa Fruzsina",
    phonenumber: "(+33)7 65 55 72 67",
    roles: "Agent",
    status: "Active",
    kyc: "No",
  },
  {
    id: 112,
    email: "sara.cruz@example.com",
    adminname: "Gogozoom",
    fullname: "Miksa Fruzsina",
    phonenumber: "(+33)7 65 55 72 67",
    roles: "Agent",
    status: "Active",
    kyc: "No",
  },
  {
    id: 112,
    email: "sara.cruz@example.com",
    adminname: "Gogozoom",
    fullname: "Miksa Fruzsina",
    phonenumber: "(+33)7 65 55 72 67",
    roles: "Agent",
    status: "Active",
    kyc: "No",
  },
  {
    id: 112,
    email: "sara.cruz@example.com",
    adminname: "Gogozoom",
    fullname: "Miksa Fruzsina",
    phonenumber: "(+33)7 65 55 72 67",
    roles: "Agent",
    status: "Active",
    kyc: "No",
  },
  {
    id: 112,
    email: "sara.cruz@example.com",
    adminname: "Gogozoom",
    fullname: "Miksa Fruzsina",
    phonenumber: "(+33)7 65 55 72 67",
    roles: "Agent",
    status: "Active",
    kyc: "No",
  },
  {
    id: 112,
    email: "sara.cruz@example.com",
    adminname: "Gogozoom",
    fullname: "Miksa Fruzsina",
    phonenumber: "(+33)7 65 55 72 67",
    roles: "Agent",
    status: "Active",
    kyc: "No",
  },
  {
    id: 112,
    email: "sara.cruz@example.com",
    adminname: "Gogozoom",
    fullname: "Miksa Fruzsina",
    phonenumber: "(+33)7 65 55 72 67",
    roles: "Agent",
    status: "Active",
    kyc: "No",
  },
];
