import React from "react";
import { Switch } from "../../components/settings/Switch";

export const AccessFilterTable = ({ data }) => {
  return (
    <>
      <table className="w-full AgentTable sm:!min-w-[unset]">
        <tbody>
          <tr>
            <td className="!border-r-[0px] text-[12px] font-arabicmed !pl-5">
              Withdrawals
            </td>
            <td className="!border-l-[0px]">
              <div className="flex items-center justify-end !pr-5">
                <Switch activeSwitch id="withdrawal-switch" />
              </div>
            </td>
          </tr>
          <tr>
            <td className="!border-r-[0px] text-[12px] font-arabicmed !pl-5">
              Members List
            </td>
            <td className="!border-l-[0px]">
              <div className="flex items-center justify-end !pr-5">
                <Switch id="list-switch" />
              </div>
            </td>
          </tr>
          <tr>
            <td className="!border-r-[0px] text-[12px] font-arabicmed !pl-5">
              Commissions
            </td>
            <td className="!border-l-[0px]">
              <div className="flex items-center justify-end !pr-5">
                <Switch id="commissions-switch" />
              </div>
            </td>
          </tr>
          <tr>
            <td className="!border-r-[0px] text-[12px] font-arabicmed !pl-5">
              Promotions
            </td>
            <td className="!border-l-[0px]">
              <div className="flex items-center justify-end !pr-5">
                <Switch id="promotions-switch" />
              </div>
            </td>
          </tr>
          <tr>
            <td className="!border-r-[0px] text-[12px] font-arabicmed !pl-5">
              Others
            </td>
            <td className="!border-l-[0px]">
              <div className="flex items-center justify-end !pr-5">
                <Switch activeSwitch id="withdrawal-switch" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
