import React from "react";
import { Link } from "react-router-dom";
import history from "../../assets/img/admin/svg/history.svg";
import transfer from "../../assets/img/admin/svg/transfer.svg";

export const CashWalletTable = () => {
  return (
    <>
      <table className="w-full AgentTable">
        <thead>
          <th>Balance</th>
          <th>Action</th>
        </thead>
        <tbody>
          <tr>
            <td>0.00</td>
            <td>
              <div className="flex items-center gap-[10px]">
                <Link
                  to="/admin/wallets/transfer"
                  className="bg-[#6FCF17] text-[#ffff] flex items-center h-[22px] w-[75px] rounded-[2px] justify-center gap-1"
                >
                  <img src={transfer} alt="" />
                  Transfer
                </Link>
                <Link
                  to="/admin/wallet/transfer/history"
                  className="bg-[#6FCF17] text-[#ffff] flex items-center h-[22px] w-[71px] rounded-[2px] justify-center gap-1"
                >
                  <img src={history} alt="" />
                  History
                </Link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
