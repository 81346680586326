import React from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Header } from "../../components/admin/Header";
import { Footer } from "../../components/admin/Footer";
import { useNavigate } from "react-router-dom";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";

// utils

import { CMSTable } from "./CMSTable";
import { CMSUtil } from "../../utils/CMSUtil";

export const CMS = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex items-start home admin-dashboard admin-dashboard-agent">
        {/* left area */}
        <Aside active={12} />

        <div className="flex-1">
          <Header heading="CMS" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>CMS Pages</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/");
                  }}
                >
                  Home
                </span>{" "}
                {/* / CMS Pages */}
              </button>
            </div>

            <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
              <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                <h1>CMS Pages</h1>
                <div className="flex items-center gap-3">
                  <button
                    className="h-11 rounded px-4"
                    onClick={(e) => {
                      navigate("/admin/CMS/create");
                    }}
                  >
                    Create New
                  </button>
                </div>
              </div>
              <div className="bottom-agent bottom-agent-page  pt-6  gap-2 flex items-end"></div>
              <div className="table-wrapper">
                <CMSTable data={CMSUtil} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
