import { useNavigate } from "react-router-dom";
import deleteImg from "../../assets/img/admin/svg/delete.svg";
import edit from "../../assets/img/admin/svg/edit.svg";
import { Popup } from "./Popup";
import { useState } from "react";
export const PaymentProviderTable = ({ data }) => {
  const [delpopup, setdelpopup] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      {delpopup && <Popup setactive={setdelpopup} />}
      <table className="w-full AgentTable">
        <thead>
          <th>ID</th>
          <th>Payment Provider Name</th>
          <th>Payment Provider Type</th>

          <th>Provider Setup Credentials</th>
          <th>Status</th>
          <th>Status</th>
          <th>Action</th>
        </thead>
        <tbody>
          <td>112</td>
          <td>Stripe Payment</td>
          <td>Deposit</td>
          <td className="p-2">
            <table className="w-full">
              <thead>
                <tr>
                  <th>S.N</th>
                  <th>Provider Key Name</th>
                  <th>Provider Key Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>01</td>
                  <td>Publishable_key</td>
                  <td>***********************************uN3</td>
                </tr>
                <tr>
                  <td>02</td>
                  <td>Secret_key</td>
                  <td>*************************************uN3</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>Active</td>
          <td>06 January 2023</td>
          <td>
            <div className="flex items-center">
              <button
                className="mr-1"
                onClick={(e) => {
                  navigate("/admin/admin/paymentprovider/create");
                }}
              >
                <img src={edit} alt="" />
              </button>
              <button
                onClick={(e) => {
                  setdelpopup(true);
                }}
              >
                <img src={deleteImg} alt="" />
              </button>
            </div>
          </td>
        </tbody>
      </table>
    </>
  );
};
