import { useNavigate } from "react-router-dom";
import editbtn from "../../assets/img/admin/svg/edit-button.svg";
export const CredentialTable = ({ data }) => {
  const navigate = useNavigate();
  return (
    <>
      <table className="w-full AgentTable">
        <thead>
          <th>ID</th>
          <th>Key</th>
          <th>Value</th>

          <th>Description</th>
          <th>Action</th>
        </thead>
        <tbody>
          {data.map((EachData) => (
            <tr>
              <td>{EachData.id}</td>
              <td>{EachData.key}</td>

              <td>{EachData.value}</td>

              <td>{EachData.description}</td>

              <td>
                <button
                  onClick={(e) => {
                    navigate("/admin/admin/credentials/create");
                  }}
                  className={`flex items-center h-5 credentialsedit justify-center`}
                >
                  Edit
                  <img src={editbtn} alt="" className="ml-1" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
