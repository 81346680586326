export const MenuTable = ({ data }) => {
  return (
    <>
      <table className="w-full AgentTable">
        <thead>
          <th>ID</th>
          <th>Name</th>
          <th>Value</th>
        </thead>
        <tbody>
          {data.map((EachData) => (
            <tr>
              <td>{EachData.id}</td>
              <td>{EachData.name}</td>
              <td>{EachData.path}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="mt-8 menutablepara">
        Admin:- On every update on menu items from Super admin, Admin has to
        rearrange the order at least for once
      </p>
    </>
  );
};
