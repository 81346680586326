import { useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";

export const useGetStakingSubscriptions = (params) => {
  return useQuery(['stakingSubscriptions', params], () =>
    privateRequest.post('admin/subscription/stakings', params)
  );
};
export const useGetSubscriptionStatistics = () => {
  return useQuery(['subscriptionStatistics'], () =>
    privateRequest.get('admin/subscription/statistics')
  );
};
