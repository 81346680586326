import React from "react";
import { dateFormatter } from "../../utils";
export const DashboardHistoryTable = ({ data, title, type }) => {
  return (
    <>
      <div className="bg-gray-900 text-white p-4">
        <div className="top-area">
          <h1>{title}</h1>
        </div>
        {type === "staking" && (
          <>
            <table className="w-full AgentTable mt-5">
              <thead>
                <th>TXID</th>
                <th>User Email</th>
                <th>Locked Months</th>
                <th>Amount</th>
              </thead>
              <tbody>
                {data?.map((history) => (
                  <tr>
                    <td>{history?._id}</td>
                    <td>{history?.userId?.email}</td>
                    <td>{history?.lockedMonths}</td>
                    <td>
                      $
                      {parseFloat(history?.subscriptionAmount)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        {type === "withdrawalRequestHistory" && (
          <>
            <table className="w-full AgentTable mt-5">
              <thead>
                <th>Date</th>
                <th>TXID</th>
                <th>User Email</th>
                <th>Status</th>
                <th>Amount</th>
              </thead>
              <tbody>
                {data?.map((history) => (
                  <tr>
                    <td>{dateFormatter(history?.createdAt)}</td>
                    <td>{history?._id}</td>
                    <td>{history?.userId?.email}</td>
                    <td>{history?.status}</td>
                    <td>
                      $
                      {parseFloat(history?.amount)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        {type === "transactionHistory" && (
          <>
            <table className="w-full AgentTable mt-5">
              <thead>
                <th>Date</th>
                <th>TXID</th>
                <th>User Email</th>
                <th>Status</th>
                <th>Amount</th>
              </thead>
              <tbody>
                {data?.map((history) => (
                  <tr>
                    <td>{dateFormatter(history?.createdAt)}</td>
                    <td>{history?._id}</td>
                    <td>{history?.userId?.email}</td>
                    <td>{history?.status}</td>
                    <td>
                      $
                      {parseFloat(history?.amount)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}

        {type === "dailyGRRPaidHistory" && (
          <>
            <table table className="w-full AgentTable mt-5">
              <thead>
                <th>Date</th>
                <th>TXID</th>
                <th>User Email</th>
                <th>Status</th>
                <th>Amount</th>
              </thead>
              <tbody>
                {data?.map((history) => (
                  <tr>
                    <td>{dateFormatter(history?.createdAt)}</td>
                    <td>{history?._id}</td>
                    <td>{history?.userId?.email}</td>
                    <td>{history?.status}</td>
                    <td>
                      $
                      {parseFloat(history?.amount)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
};
