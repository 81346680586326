export const AdminConfigureUtil = [
  {
    id: 112,
    key: "Allowed Currencies",
    value: "BRL, Euro, Pound Sterling, Rupee, United Sates Dollar, UYI, Yen",
  },
  {
    id: 112,
    key: "Assigned Casino Providers",
    value: "Ezugi, Evolution",
  },
];
