function formatCurrencyName(name) {
    const parts = name.split('_');
    if (parts.length === 2) {
      return `${parts[0]} (${parts[1]})`;
    }
    return name; // Return the original name if it doesn't contain an underscore
  }

  export default formatCurrencyName;

  export function formatBalance(balance, decimalPlaces = 6) {
    // Check if balance is a number and is not null or undefined
    if (typeof balance === 'number' && !isNaN(balance)) {
      return balance.toFixed(decimalPlaces);
    }
    // Default formatting for non-numeric or undefined values
    return (0).toFixed(decimalPlaces);
  }