export const DepositeUtils = [
  {
    id: 112,
    username: "rashika",
    status: "Complete",
    utr: "123456789123QX",
    download: "www.google.com",
    amount: "50 PHP",
    remark: "Casino bonus testing",
    actionby: "sheinAdmin1",
    requested: "17-08-2023",
    actionat: "12-08-2023",
    actions: "Complete",
  },
  {
    id: 112,
    username: "rashika",
    status: "Complete",
    utr: "123456789123QX",
    download: "www.google.com",
    amount: "50 PHP",
    remark: "Casino bonus testing",
    actionby: "sheinAdmin1",
    requested: "17-08-2023",
    actionat: "12-08-2023",
    actions: "Complete",
  },
  {
    id: 112,
    username: "rashika",
    status: "Complete",
    utr: "123456789123QX",
    download: "www.google.com",
    amount: "50 PHP",
    remark: "Casino bonus testing",
    actionby: "sheinAdmin1",
    requested: "17-08-2023",
    actionat: "12-08-2023",
    actions: "Complete",
  },
  {
    id: 112,
    username: "rashika",
    status: "Complete",
    utr: "123456789123QX",
    download: "www.google.com",
    amount: "50 PHP",
    remark: "Casino bonus testing",
    actionby: "sheinAdmin1",
    requested: "17-08-2023",
    actionat: "12-08-2023",
    actions: "Complete",
  },
  {
    id: 112,
    username: "rashika",
    status: "Complete",
    utr: "123456789123QX",
    download: "www.google.com",
    amount: "50 PHP",
    remark: "Casino bonus testing",
    actionby: "sheinAdmin1",
    requested: "17-08-2023",
    actionat: "12-08-2023",
    actions: "Complete",
  },
  {
    id: 112,
    username: "rashika",
    status: "Complete",
    utr: "123456789123QX",
    download: "www.google.com",
    amount: "50 PHP",
    remark: "Casino bonus testing",
    actionby: "sheinAdmin1",
    requested: "17-08-2023",
    actionat: "12-08-2023",
    actions: "Complete",
  },
  {
    id: 112,
    username: "rashika",
    status: "Complete",
    utr: "123456789123QX",
    download: "www.google.com",
    amount: "50 PHP",
    remark: "Casino bonus testing",
    actionby: "sheinAdmin1",
    requested: "17-08-2023",
    actionat: "12-08-2023",
    actions: "Complete",
  },
  {
    id: 112,
    username: "rashika",
    status: "Complete",
    utr: "123456789123QX",
    download: "www.google.com",
    amount: "50 PHP",
    remark: "Casino bonus testing",
    actionby: "sheinAdmin1",
    requested: "17-08-2023",
    actionat: "12-08-2023",
    actions: "Complete",
  },
  {
    id: 112,
    username: "rashika",
    status: "Complete",
    utr: "123456789123QX",
    download: "www.google.com",
    amount: "50 PHP",
    remark: "Casino bonus testing",
    actionby: "sheinAdmin1",
    requested: "17-08-2023",
    actionat: "12-08-2023",
    actions: "Complete",
  },
];
