import React, { useState } from "react";
export const Alert = ({
  setactive,
  title,
  text,
  buttonText,
  confirmSubadminBlock,
  confirmReason = null,
  needReason
}) => {
  const [declineReason, setDeclineReason] = useState(null);
  if (needReason) {
    confirmReason(declineReason);
  }

  return (
    <div className="delete-popup">
      <span
        className="bg-popup"
        onClick={() => setactive(false)}
      ></span>
      <div className="flex flex-col rounded-lg py-10 px-20 items-center justify-center">
        <h1>{title}</h1>
        <p className="my-3">{text}</p>
        {needReason && (
          <div className="form-group flex flex-col my-5 w-full">
            <label htmlFor="reason" className="text-white mb-2">Note for decline?</label>
            <textarea 
              className="px-5 py-1 rounded-sm"
              onChange={(e) => setDeclineReason(e.target.value)} 
              placeholder="Write some note for user"
              style={{ width: '100%', boxSizing: 'border-box' }}
            ></textarea>
          </div>
        )}
        <div className="buttons-wrapper gap-5 flex items-center justify-center">
          <button
            className="px-5 py-2 bg-[#49D9A6]"
            onClick={() => confirmSubadminBlock(true)}
          >
            {buttonText}
          </button>
          <button
            className="px-5 py-2 del"
            onClick={() => setactive(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
