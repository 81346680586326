import React, { useState } from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Header } from "../../components/admin/Header";
import { Footer } from "../../components/admin/Footer";
import { useNavigate } from "react-router-dom";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";

// utils
import { Coin } from "../../utils/coins";
import { PayDropdown } from "../../components/admin/PayDropdown";
import { TransferDone } from "../../components/admin/TransferDone";
import { WithdrawDone } from "../../components/admin/WithdrawDone";
export const WalletWithdraw = () => {
  const navigate = useNavigate();
  const [withdraw, setwithdraw] = useState(false);
  return (
    <div>
      <div className="flex items-start home admin-dashboard  admin-dashboard-agent">
        {/* left area */}
        <Aside active="wallet/1" />

        <div className="flex-1">
          <Header heading="Agents" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Wallets</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/");
                  }}
                >
                  Home
                </span>{" "}
                / Withdraw
              </button>
            </div>

            <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
              <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                <h1>Withdraw</h1>
              </div>
              <div className="bottom-agent bottom-agent-page   pt-6    gap-2 flex items-end"></div>

              <div className="">
                <div className="mb-6">
                  <label
                    htmlFor="#"
                    className="text-[18px] font-arabicreg text-[#fff] mb-3 block"
                  >
                    Select Coin
                  </label>
                  <PayDropdown
                    stake
                    classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
                    dollar={Coin[0].img}
                    heading={Coin[0].heading}
                    list={Coin}
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="#"
                    className="text-[18px] font-arabicreg text-[#fff] mb-3 block"
                  >
                    Wallet address
                  </label>

                  <input
                    type="text"
                    value="0xcd73f4e8f50c48267e26348df60e6d27c5dbf168"
                    className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6 sm:text-[14px]"
                  />
                </div>
                <div>
                  <label
                    htmlFor="#"
                    className="text-[18px] font-arabicreg text-[#fff] mb-3 block"
                  >
                    Withdrawal Amount
                  </label>

                  <input
                    type="text"
                    value="0.00"
                    className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6 sm:text-[14px]"
                  />
                </div>
                <button
                  onClick={(e) => {
                    setwithdraw(true);
                  }}
                  className="text-[18px] text-[#111010] bg-[#6FCF17] h-[50px]
                           w-[100%] rounded font-bold mt-[93px]"
                >
                  Submit Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {withdraw && <WithdrawDone setwithdraw={setwithdraw} />}
      <Footer />
    </div>
  );
};
