import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";

import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./contexts/AuthContext";
import { WalletProvider } from "./contexts/WalletContext";
import { AccessFilter } from "./pages/admin/AccessFilter";
import { AddNewBank } from "./pages/admin/AddNewBank";
import { AdminBanner } from "./pages/admin/AdminBanner";
import { AdminConfigure } from "./pages/admin/AdminConfigure";
import { AdminCredentials } from "./pages/admin/AdminCredentials";
import { AdminMenu } from "./pages/admin/AdminMenu";
import { AdminUserRegistration } from "./pages/admin/AdminUserRegistration";
import { AdminWallet } from "./pages/admin/AdminWallet";
import { AgentReport } from "./pages/admin/AgentReport";
import { Agents } from "./pages/admin/Agents";
import { BankDetails } from "./pages/admin/BankDetails";
import { Bonus } from "./pages/admin/Bonus";
import { CMS } from "./pages/admin/CMS";
import { CreateAgents } from "./pages/admin/CreateAgents";
import { CreateBanner } from "./pages/admin/CreateBanner";
import { CreateCMS } from "./pages/admin/CreateCMS";
import { CreateCredentials } from "./pages/admin/CreateCredentials";
import { CreatePaymentProvider } from "./pages/admin/CreatePaymentProvider";
import { CreatePlayer } from "./pages/admin/CreatePlayer";
import { CreatePromoCode } from "./pages/admin/CreatePromoCode";
import { CreateSubAdmin } from "./pages/admin/CreateSubAdmin";
import { CreateTransaction } from "./pages/admin/CreateTransaction";
import { CryptoWithdrawals } from "./pages/admin/CryptoWithdrawals";
import { Dashboard } from "./pages/admin/Dashboard";
import { DepositeRequest } from "./pages/admin/DepositeRequest";
import { DirectReferral } from "./pages/admin/DirectReferral";
import { EditAgents } from "./pages/admin/EditAgents";
import { EditBanner } from "./pages/admin/EditBanner";
import { EditPlayer } from "./pages/admin/EditPlayer";
import { EditSubAdmin } from "./pages/admin/EditSubAdmin";
import { Leaders } from "./pages/admin/Leaders";
import { MasterLeaders } from "./pages/admin/MasterLeaders";
import { MemberList } from "./pages/admin/MemberList";
import { MembersWallet } from "./pages/admin/MembersWallet";
import { Notifications } from "./pages/admin/Notifications";
import { PaymentProviders } from "./pages/admin/PaymentProviders";
import { PendingWithdrawl } from "./pages/admin/PendingWithdrawl";
import { PlayerReport } from "./pages/admin/PlayerReport";
import { Players } from "./pages/admin/Players";
import { Qualified } from "./pages/admin/Qualified";
import { RoyaltyBonus } from "./pages/admin/RoyaltyBonus";
import { SiteMantainence } from "./pages/admin/SiteMantainence";
import { StakingProduct } from "./pages/admin/StakingProduct";
import { StakingReport } from "./pages/admin/StakingReport";
import { StakingSubscription } from "./pages/admin/StakingSubscription";
import { Subadmins } from "./pages/admin/Subadmins";
import { TeamLeaders } from "./pages/admin/TeamLeaders";
import { TenantsPaymentConfigurations } from "./pages/admin/TenantsPaymentConfigurations";
import { ThemeSettings } from "./pages/admin/ThemeSettings";
import { TransactionReport } from "./pages/admin/TransactionReport";
import { Transactions } from "./pages/admin/Transactions";
import { Transfer } from "./pages/admin/Transfer";
import { TransferHistory } from "./pages/admin/TransferHistory";
import { MemberBalanceReport } from "./pages/admin/UserBalanceReport";
import { WalletDeposite } from "./pages/admin/WalletDeposite";
import { WalletWithdraw } from "./pages/admin/WalletWithdraw";
import { WithdrawalDetails } from "./pages/admin/WithdrawalDetails";
import { ForgetPassword } from "./pages/auth/ForgetPassword";
import { Signin } from "./pages/auth/Signin";
import { SignUp } from "./pages/auth/SignUp";
import PrivateRoute from "./PrivateRoute";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/agent",
    element: (
      <PrivateRoute>
        <Agents />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/agent/create",
    element: (
      <PrivateRoute>
        <CreateAgents />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/agent/edit",
    element: (
      <PrivateRoute>
        <EditAgents />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/tenants-payment-configuration",
    element: (
      <PrivateRoute>
        <TenantsPaymentConfigurations />
      </PrivateRoute>
    ),
  },

  // Subadmins
  {
    path: "/admin/sub-admin",
    element: (
      <PrivateRoute>
        <Subadmins />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/sub-admin/access-filter",
    element: <AccessFilter />,
  },
  {
    path: "/admin/sub-admin/create",
    element: (
      <PrivateRoute>
        <CreateSubAdmin />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/sub-admin/access-filter",
    element: <AccessFilter />,
  },
  {
    path: "/admin/sub-admin/create",
    element: <CreateSubAdmin />,
  },
  {
    path: "/admin/sub-admin/edit/:id",
    element: (
      <PrivateRoute>
        <EditSubAdmin />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/players",
    element: (
      <PrivateRoute>
        <Players />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/players/create",
    element: (
      <PrivateRoute>
        <CreatePlayer />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/players/edit",
    element: (
      <PrivateRoute>
        <EditPlayer />
      </PrivateRoute>
    ),
  },
  // Transactions

  {
    path: "/admin/transactions",
    element: (
      <PrivateRoute>
        <Transactions />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/transactions/create",
    element: (
      <PrivateRoute>
        <CreateTransaction />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/notifications",
    element: (
      <PrivateRoute>
        <Notifications />
      </PrivateRoute>
    ),
  },

  // SiteMantainence

  {
    path: "/admin/site-mantainence",
    element: (
      <PrivateRoute>
        <SiteMantainence />
      </PrivateRoute>
    ),
  },

  // Bonus

  {
    path: "/admin/bonus",
    element: (
      <PrivateRoute>
        <Bonus />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/bonus/create",
    element: (
      <PrivateRoute>
        <CreatePromoCode />
      </PrivateRoute>
    ),
  },

  // CMS
  {
    path: "/admin/CMS",
    element: (
      <PrivateRoute>
        <CMS />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/CMS/create",
    element: (
      <PrivateRoute>
        <CreateCMS />
      </PrivateRoute>
    ),
  },

  // AgentReport
  {
    path: "/admin/agent/report",
    element: (
      <PrivateRoute>
        <AgentReport />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/player/report",
    element: (
      <PrivateRoute>
        <PlayerReport />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/transaction/report",
    element: (
      <PrivateRoute>
        <TransactionReport />
      </PrivateRoute>
    ),
  },

  // RejectedWithdrawl
  // {
  //   path: "/admin/withdrawl/rejected",
  //   element: (
  //     <PrivateRoute>
  //       <RejectedWithdrawl />
  //     </PrivateRoute>
  //   ),
  // },
  {
    path: "/admin/withdrawls",
    element: (
      <PrivateRoute>
        <PendingWithdrawl />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/withdrawls/details/:id",
    element: (
      <PrivateRoute>
        <WithdrawalDetails />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/crypto-withdrawls",
    element: (
      <PrivateRoute>
        <CryptoWithdrawals />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/crypto-withdrawls/details/:id",
    element: (
      <PrivateRoute>
        <WithdrawalDetails />
      </PrivateRoute>
    ),
  },
  // {
  //   path: "/admin/withdrawl/verified",
  //   element: (
  //     <PrivateRoute>
  //       <VerifiedWithdrawal />
  //     </PrivateRoute>
  //   ),
  // },

  // payment

  {
    path: "/admin/payment/bank/details",
    element: (
      <PrivateRoute>
        <BankDetails />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/payment/bank/create",
    element: (
      <PrivateRoute>
        <AddNewBank />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/payment/bank/deposite",
    element: (
      <PrivateRoute>
        <DepositeRequest />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/admin/credentials",
    element: (
      <PrivateRoute>
        <AdminCredentials />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/admin/credentials/create",
    element: (
      <PrivateRoute>
        <CreateCredentials />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/paymentprovider",
    element: (
      <PrivateRoute>
        <PaymentProviders />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/admin/paymentprovider/create",
    element: (
      <PrivateRoute>
        <CreatePaymentProvider />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/admin/configure",
    element: (
      <PrivateRoute>
        <AdminConfigure />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/admin/banner",
    element: (
      <PrivateRoute>
        <AdminBanner />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/admin/banner/edit",
    element: (
      <PrivateRoute>
        <EditBanner />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/admin/banner/create",
    element: (
      <PrivateRoute>
        <CreateBanner />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/admin/menu",
    element: (
      <PrivateRoute>
        <AdminMenu />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/admin/theme/settings",
    element: (
      <PrivateRoute>
        <ThemeSettings />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/admin/user/registration",
    element: (
      <PrivateRoute>
        <AdminUserRegistration />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/memberlist",
    element: (
      <PrivateRoute>
        <MemberList />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/member-balance-report",
    element: (
      <PrivateRoute>
        <MemberBalanceReport />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/wallet/members",
    element: (
      <PrivateRoute>
        <MembersWallet />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/wallets",
    element: (
      <PrivateRoute>
        <AdminWallet />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/wallets/deposit/:currency",
    element: (
      <PrivateRoute>
        <WalletDeposite />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/wallets/withdraw",
    element: (
      <PrivateRoute>
        <WalletWithdraw />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/wallets/transfer",
    element: (
      <PrivateRoute>
        <Transfer />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/wallet/transfer/history",
    element: (
      <PrivateRoute>
        <TransferHistory />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/subscription",
    element: (
      <PrivateRoute>
        <StakingSubscription />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/reports/staking",
    element: (
      <PrivateRoute>
        <StakingReport />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/reports/products",
    element: (
      <PrivateRoute>
        <StakingProduct />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/commission/directReferral",
    element: (
      <PrivateRoute>
        <DirectReferral />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/commission/royaltyBonus",
    element: (
      <PrivateRoute>
        <RoyaltyBonus />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/leadership/leaders",
    element: (
      <PrivateRoute>
        <Leaders />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/leadership/team-leaders",
    element: (
      <PrivateRoute>
        <TeamLeaders />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/leadership/master-leaders",
    element: (
      <PrivateRoute>
        <MasterLeaders />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/leadership/qualified",
    element: (
      <PrivateRoute>
        <Qualified />
      </PrivateRoute>
    ),
  },
  {
    path: "/login",
    element: <Signin />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <WalletProvider>

          <RouterProvider router={router} />
          <ToastContainer position="bottom-right" theme="dark" />
        </WalletProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
