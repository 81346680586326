import React, { useState } from "react";

// components
import { Link } from "react-router-dom";
import { CheckboxStyleSignUp } from "../../components/CheckboxStyleSignUp";

// stylesheet
import "../../assets/css/sign-in.css";

// images
import logo from "../../assets/img/admin/svg/logo.svg";
import eye from "../../assets/img/auth/eye.svg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const SignUp = () => {
  const [type, setType] = useState(true);

  return (
    <div className="SignIn LayerUp">
      <ToastContainer />
      <div className="inner-work-sign-in  flex items-center justify-center w-[597px]">
        <div className="right-side   w-[444px] py-10">
          <form className="flex flex-col h-full">
            <img src={logo} alt="" className="w-[280px] mx-auto mb-8" />
            <h1 className="mb-8">Join GAMEBOX</h1>

            <div className="input-wrapper ">
              <label htmlFor="email">Email</label>
              <input
                placeholder="Type your email here"
                className="rounded-lg px-6 mt-3"
                type="email"
                name="email"
                id="email"
                required
              />
            </div>
            <div className="input-wrapper mt-4 mb-5">
              <label htmlFor="password">Password</label>
              <input
                type={type ? "password" : type}
                placeholder="Enter your password"
                id="password"
                name="password"
                className="rounded-lg px-6 mt-3"
                required
              />
              <img
                src={eye}
                className="eye-icon"
                onClick={(e) => {
                  setType(!type);
                }}
                alt="eye icon"
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="sponsor">
                Enter Referral / Promo Code (Optional)
              </label>
              <input
                type="text"
                name="sponsor"
                id="sponsor"
                placeholder="Enter Referral / Promo Code (Optional)"
                className="rounded-lg px-6 mt-3"
              />
            </div>
            <div className="agreement-list mt-4">
              <div className="list-inner flex items-start">
                <CheckboxStyleSignUp type="checkbox" id="user-agree" />
                <p className="ml-3">
                  I agree to the User <a href="/">Agreement & Confirm</a> i am
                  at I east 18 years old
                </p>
              </div>

              <div className="list-inner mt-2 flex items-center">
                <CheckboxStyleSignUp type="checkbox" id="marketing" />
                <p className="ml-3" style={{ fontSize: 12 }}>
                  I agree to receive marketing promotions from GAMEBOX
                </p>
              </div>
            </div>

            <button className="w-full rounded-lg mt-4 mb-4" type="submit">
              Sign Up
            </button>
            <p className="dont-have-p flex justify-center">
              Already have an account! <Link to="/">Sign In</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};
