export const MembersWalletUtils = [
  {
    id: 112,
    username: "johnsmith8546",
    email: "johnsmith8546@gmail.com",
    wallet: "Binance",
    currency: "EURO",
    date: "2023-11-28",
    balance: "0.00",
    status: {
      color: "#0DC10D",
      text: "Completed",
    },
  },
  {
    id: 112,
    username: "johnsmith8546",
    email: "johnsmith8546@gmail.com",
    wallet: "Binance",
    currency: "EURO",
    date: "2023-11-28",
    balance: "0.00",
    status: {
      color: "#0DC10D",
      text: "Completed",
    },
  },
  {
    id: 112,
    username: "johnsmith8546",
    email: "johnsmith8546@gmail.com",
    wallet: "Binance",
    currency: "EURO",
    date: "2023-11-28",
    balance: "0.00",
    status: {
      color: "#0DC10D",
      text: "Completed",
    },
  },
  {
    id: 112,
    username: "johnsmith8546",
    email: "johnsmith8546@gmail.com",
    wallet: "Binance",
    currency: "EURO",
    date: "2023-11-28",
    balance: "0.00",
    status: {
      color: "#F0AD4E",
      text: "Pending",
    },
  },
  {
    id: 112,
    username: "johnsmith8546",
    email: "johnsmith8546@gmail.com",
    wallet: "Binance",
    currency: "EURO",
    date: "2023-11-28",
    balance: "0.00",
    status: {
      color: "#F0AD4E",
      text: "Pending",
    },
  },
  {
    id: 112,
    username: "johnsmith8546",
    email: "johnsmith8546@gmail.com",
    wallet: "Binance",
    currency: "EURO",
    date: "2023-11-28",
    balance: "0.00",
    status: {
      color: "#0DC10D",
      text: "Completed",
    },
  },
  {
    id: 112,
    username: "johnsmith8546",
    email: "johnsmith8546@gmail.com",
    wallet: "Binance",
    currency: "EURO",
    date: "2023-11-28",
    balance: "0.00",
    status: {
      color: "#0DC10D",
      text: "Completed",
    },
  },
  {
    id: 112,
    username: "johnsmith8546",
    email: "johnsmith8546@gmail.com",
    wallet: "Binance",
    currency: "EURO",
    date: "2023-11-28",
    balance: "0.00",
    status: {
      color: "#0DC10D",
      text: "Completed",
    },
  },
];
