import React, { useEffect, useState } from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { useNavigate } from "react-router-dom";
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
import search from "../../assets/img/admin/svg/search.svg";

// utils
import { useQueryClient } from "react-query";
import {
  useGetStakingSubscriptions,
  useGetSubscriptionStatistics,
} from "../../queries/staking";
import { StakingSubscriptionTable } from "./StakingSubscriptionTable";

export const StakingSubscription = () => {
  const [activepagination, setActivePagination] = useState(1);
  const [value, onChange] = useState(new Date());
  const [DateValue, setDateValue] = useState("");
  const [calenderState, setCalender] = useState(false);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    search: "",
    type: "",
    flag: "",
    startDate: "",
    endDate: "",
    status: "",
  });
  const [queryParams, setQueryParams] = useState({
    status: "",
    limit: 10,
    page: 1,
    email: "",
  });
  const queryClient = useQueryClient();
  // Update state when input fields change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    // Here you can reset the page number if needed
    // setQueryParams({ ...queryParams, page: 1 });
    fetchData();
  };

  const handleResetFilters = () => {
    setFilters({
      search: "",
      type: "",
      flag: "",
      startDate: "",
      endDate: "",
      status: "",
    });

    fetchData();
  };

  const { data, isLoading, isError } = useGetStakingSubscriptions(filters);

  // Fetch data when queryParams change
  useEffect(() => {
    fetchData();
  }, [filters]);

  const fetchData = () => {
    // No need to invalidate queries since we're not using URL parameters anymore
    queryClient.refetchQueries(["stakingSubscriptions", queryParams]);
  };
  const {
    data: statsData,
    isLoading: isStatsLoading,
    isError: isStatsError,
  } = useGetSubscriptionStatistics();

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
  };

  const handleResetFilter = () => {
    setFilters({
      email: "",
      type: "",
      flag: "",
      startDate: "",
      endDate: "",
      status: "",
    });
  };

  const handlePaginationClick = (pageNumber) => {
    setActivePagination(pageNumber);

    setFilters((prevFilters) => ({ ...prevFilters, page: pageNumber }));
  };

  if (isError) return <div>Error fetching data</div>;

  return (
    <div>
      <div className="flex items-start home admin-dashboard  admin-dashboard-agent">
        {/* left area */}
        <Aside active="subscribe" />

        <div className="flex-1">
          <Header heading="Home" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Staking Subscriptions</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/");
                  }}
                >
                  Home
                </span>{" "}
                {/* / Staking Subscriptions */}
              </button>
            </div>

            <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
              <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                <div className="statistics-wrapper flex flex-wrap justify-around p-4 text-white">
                  {isStatsLoading ? (
                    <div>Loading statistics...</div>
                  ) : isStatsError ? (
                    <div>Error loading statistics</div>
                  ) : (
                    statsData &&
                    statsData?.data?.data.map((stat, index) => (
                      <div
                        key={index}
                        className="stat-card bg-gray-900 shadow-lg rounded-lg p-6 m-2 flex-1"
                      >
                        <div className="stat-header mb-4">
                          <h3 className="text-xl font-semibold">
                            {" "}
                            {stat._id || "Unknown"}
                          </h3>
                        </div>
                        <div className="stat-body text-white ">
                          <p className="mb-2 ">
                            Total Amount:{" "}
                            <span className="font-bold">
                              ${stat.totalAmount}
                            </span>
                          </p>
                          <p>
                            Number of Subscriptions:{" "}
                            <span className="font-bold">{stat.count}</span>
                          </p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="bottom-agent bottom-agent-page pt-6 pb-4 gap-2 flex items-end">
                <div className="input-container w-60">
                  <label htmlFor="email" className="mb-3 block">
                    Search
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <img src={search} alt="" />
                    <input
                      type="text"
                      placeholder="Search by email, TxID"
                      value={filters.search}
                      onChange={(e) =>
                        handleFilterChange("search", e.target.value)
                      }
                      className="flex-1 w-full h-full bg-transparent border-0 outline-none ml-2"
                    />
                  </div>
                </div>

                <div className="input-container w-32">
                  <label htmlFor="status" className="mb-3 block">
                    Status
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <select
                      name="status"
                      id="status"
                      value={filters.status}
                      onChange={(e) =>
                        handleFilterChange("status", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      <option value="pending">Pending</option>
                      <option value="active">Active</option>
                      <option value="completed">Completed</option>
                      <option value="processing">Processing</option>
                      <option value="approved">Approved</option>
                      <option value="confirmed">Confirmed</option>
                      <option value="declined">Declined</option>
                    </select>
                  </div>
                </div>

                <button
                  onClick={handleResetFilters}
                  className="rounded-lg px-5"
                >
                  Reset Filter
                </button>
              </div>

              <div className="table-wrapper">
                <StakingSubscriptionTable
                  onPageChange={handlePaginationClick}
                  data={data?.data.stakingSubscriptions || []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
