import React, { useState } from "react";
export const UpdateRankModal = ({
    setactive,
    title,
    text,
    buttonText,
    UserRankUpdate,
    selectedRank
}) => {
    const [rank, setRank] = useState(null);

    return (
      <div className="delete-popup">
        <span className="bg-popup" onClick={() => setactive(false)}></span>
        <div className="flex flex-col rounded-lg py-10 px-20 items-center justify-center">
          <h1>{title}</h1>
          <p className="my-3">{text}</p>
          <select
            className="bg-black text-white w-full my-3 py-2 px-6"
            onChange={(e) => selectedRank(e.target.value)}
          >
            <option value="Player">Player</option>
            <option value="JuniorAssociate">Junior Associate</option>
            <option value="SeniorPartner">Senior Partner</option>
            <option value="ManagingDirector">Managing Director</option>
          </select>
          <div className="buttons-wrapper gap-5 flex items-center justify-center">
            <button
              className="px-5 py-2 bg-[#6FCF17]"
              onClick={() => UserRankUpdate(true)}
            >
              {buttonText}
            </button>
            <button className="px-5 py-2 del" onClick={() => setactive(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
};
