import React, { useState, createContext, useContext } from 'react';

// Create the context
const WalletContext = createContext();

// Create a provider component
export const WalletProvider = ({ children }) => {
  const [wallet, setWallet] = useState({});

  const updateWallet = (newWalletData) => {
    setWallet(newWalletData);
  };

  return (
    <WalletContext.Provider value={{ wallet, updateWallet }}>
      {children}
    </WalletContext.Provider>
  );
};

// Custom hook for easy usage of the context
export const useWallet = () => useContext(WalletContext);
