import { CheckboxCustom } from "./CheckboxCustom";
export const UserRegistrationTable = ({ data }) => {
  return (
    <>
      <table className="w-full AgentTable UserRegistrationTable">
        <thead>
          <th>Field Name</th>
          <th className="text-center">Hide</th>
          <th className="text-center">Show as Optional</th>
          <th className="text-center">Show as Required</th>
        </thead>
        <tbody>
          {data.map((EachData) => (
            <tr>
              <td>{EachData.name}</td>

              <td width="25%">
                <div className="flex checkbox-user items-center justify-center">
                  <CheckboxCustom
                    type="checkbox"
                    id={`checkbox_${Math.random() * 100}`}
                  />
                </div>
              </td>
              <td width="25%">
                <div className="flex checkbox-user  items-center justify-center">
                  <CheckboxCustom
                    type="checkbox"
                    id={`checkbox_${Math.random() * 100}`}
                  />
                </div>
              </td>
              <td width="25%">
                <div className="flex  checkbox-user items-center justify-center">
                  <CheckboxCustom
                    type="checkbox"
                    id={`checkbox_${Math.random() * 100}`}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex buttons-wrapper items-center mt-8 justify-end">
        <button className="h-11 px-4 rounded mr-3">Submit</button>
        <button className="h-11 px-4 rounded outline-button">Cancel</button>
      </div>
    </>
  );
};
