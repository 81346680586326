export const BonusUtil = [
  {
    id: 112,
    promo: "*****678",
    platform: "facebook",
    validfrom: "Jul 19, 2023",
    validtill: "Jul 22, 2023",
    bonustype: "Upto",
    bonusamount: "10",
    status: "Active",
    updated: "Jun 14, 2023",
  },
  {
    id: 112,
    promo: "*****678",
    platform: "facebook",
    validfrom: "Jul 19, 2023",
    validtill: "Jul 22, 2023",
    bonustype: "Upto",
    bonusamount: "10",
    status: "Active",
    updated: "Jun 14, 2023",
  },
  {
    id: 112,
    promo: "*****678",
    platform: "facebook",
    validfrom: "Jul 19, 2023",
    validtill: "Jul 22, 2023",
    bonustype: "Upto",
    bonusamount: "10",
    status: "Active",
    updated: "Jun 14, 2023",
  },
  {
    id: 112,
    promo: "*****678",
    platform: "facebook",
    validfrom: "Jul 19, 2023",
    validtill: "Jul 22, 2023",
    bonustype: "Upto",
    bonusamount: "10",
    status: "Active",
    updated: "Jun 14, 2023",
  },
  {
    id: 112,
    promo: "*****678",
    platform: "facebook",
    validfrom: "Jul 19, 2023",
    validtill: "Jul 22, 2023",
    bonustype: "Upto",
    bonusamount: "10",
    status: "Active",
    updated: "Jun 14, 2023",
  },
  {
    id: 112,
    promo: "*****678",
    platform: "facebook",
    validfrom: "Jul 19, 2023",
    validtill: "Jul 22, 2023",
    bonustype: "Upto",
    bonusamount: "10",
    status: "Active",
    updated: "Jun 14, 2023",
  },
  {
    id: 112,
    promo: "*****678",
    platform: "facebook",
    validfrom: "Jul 19, 2023",
    validtill: "Jul 22, 2023",
    bonustype: "Upto",
    bonusamount: "10",
    status: "Active",
    updated: "Jun 14, 2023",
  },
  {
    id: 112,
    promo: "*****678",
    platform: "facebook",
    validfrom: "Jul 19, 2023",
    validtill: "Jul 22, 2023",
    bonustype: "Upto",
    bonusamount: "10",
    status: "Active",
    updated: "Jun 14, 2023",
  },
];
