import React from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Header } from "../../components/admin/Header";
import { Footer } from "../../components/admin/Footer";

// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
import { useNavigate } from "react-router-dom";
import { isAmountValid } from "../../utils";
import { useMutation } from "react-query";
import { privateRequest } from "../../config/axios.config";
import { toast } from "react-toastify";
// utils

export const CreateTransaction = () => {
  const navigate = useNavigate();

  const [form, setForm] = React.useState({
    user: "",
    amount: "",
  });

  const transferMutation = useMutation((data) =>
    privateRequest.post("admin/wallet/transferBalance", data)
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount" && !isAmountValid(value)) return;
    setForm({ ...form, [name]: value });
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!form.user || !form.amount)
      return toast.error("All fields are required");

    transferMutation.mutate(form, {
      onSuccess: (res) => {
        toast.success(res.data.message);
        navigate("/admin/transactions");
      },
      onError: (err) => {
        toast.error(err.response.data.message);
      },
    });
  };

  return (
    <div>
      <div className="flex items-start home admin-dashboard">
        {/* left area */}
        <Aside active={6} />

        <div className="flex-1">
          <Header heading="Transactions" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Transfer Fund</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/");
                  }}
                >
                  Home
                </span>{" "}
                {/* / */}
                <span
                  className="mr-1"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/admin/transactions");
                  }}
                >
                  {/* Transactions */}
                </span>{" "}
                {/* / Create */}
              </button>
            </div>

            <div className="agent-wrapper create-agents mt-8  rounded-xl">
              <div className="top-agent  rounded-tl-xl  rounded-tr-xl py-7 px-6 top-agent-page flex items-center justify-between">
                <h1>Transfer Fund</h1>
              </div>
              <div className="forms-wrapper px-6 py-6">
                <form
                  onSubmit={submitForm}
                  className="grid grid-cols-2 gap-x-3 gap-y-6"
                >
                  {/* <div className="input-wrapper">
                    <label htmlFor="">
                      Transfer To <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <select name="" id="">
                        <option value="Select your currency">Agent</option>
                      </select>
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Target Email <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <select name="" id="">
                        <option value="Select your currency">
                          Select your email
                        </option>
                      </select>
                    </div>
                  </div> */}
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Transfer To <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input
                        name="user"
                        value={form.user}
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter user"
                      />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Amount <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input
                        name="amount"
                        value={form.amount}
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter your amount"
                      />
                    </div>
                  </div>
                  {/* <div className="input-wrapper">
                    <label htmlFor="">
                      Source Currency Id <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <select name="" id="">
                        <option value="Select your currency">
                          Select your currency
                        </option>
                      </select>
                    </div>
                  </div> */}
                  {/* <div className="input-wrapper col-span-2">
                    <label htmlFor="">
                      Comment <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input type="email" placeholder="Enter your comment" />
                    </div>
                  </div> */}

                  <div className="input-wrapper  flex items-center justify-end col-span-2">
                    <div className="flex buttons-wrapper items-center">
                      <button
                        disabled={transferMutation.isLoading}
                        className="h-11 px-4 rounded mr-3"
                      >
                        {transferMutation.isLoading ? "Loading..." : "Transfer"}
                      </button>
                      <button className="h-11 px-4 rounded outline-button">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
