import { useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";

export const useGetWithdrawals = (status, search) =>
  useQuery(["withdrawals", status], () =>
    privateRequest.get(
      `admin/withdrawal/withdrawal-list?flag=withdraw&${status ? `&status=${status}&search=${search}` : ""
      }`
    )
  );

  export const useGetCryptoWithdrawals = (status, search) =>
  useQuery(["cryptoWithdrawals", status], () =>
    privateRequest.get(
      `admin/crypto-withdrawal/withdrawal-list?flag=withdraw_crypto_balance&${status ? `&status=${status}&search=${search}` : ""
      }`
    )
  );
