export const ProductStake = [
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Currency: "BTC",
    rp: "20",
    Descrpt: "New",
    Platform: "Cubeshop",
    Sponsor: "Shi",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Currency: "BTC",
    rp: "20",
    Descrpt: "New",
    Platform: "Cubeshop",
    Sponsor: "Shi",
    status: {
      color: "#17A2B8",
      text: "Free",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Currency: "BTC",
    rp: "20",
    Descrpt: "New",
    Platform: "Cubeshop",
    Sponsor: "Shi",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Currency: "BTC",
    rp: "20",
    Descrpt: "New",
    Platform: "Cubeshop",
    Sponsor: "Shi",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Currency: "BTC",
    rp: "20",
    Descrpt: "New",
    Platform: "Cubeshop",
    Sponsor: "Shi",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Date: "28-05-2023",
    Currency: "BTC",
    rp: "20",
    Descrpt: "New",
    Platform: "Cubeshop",
    Sponsor: "Shi",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
];
