export const ReportStake = [
  {
    Txid: "111",
    Username: "sato",
    Plan: "Lite",
    Date: "28-05-2023",
    Amount: "100.00",
    Currency: "E-money",
    Descrpt: "New",
    Sponsor: "Shi",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Plan: "Lite",
    Date: "28-05-2023",
    Amount: "100.00",
    Currency: "E-money",
    Descrpt: "New",
    Sponsor: "Shi",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Plan: "Lite",
    Date: "28-05-2023",
    Amount: "100.00",
    Currency: "E-money",
    Descrpt: "New",
    Sponsor: "Shi",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Plan: "Lite",
    Date: "28-05-2023",
    Amount: "100.00",
    Currency: "E-money",
    Descrpt: "New",
    Sponsor: "Shi",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Plan: "Lite",
    Date: "28-05-2023",
    Amount: "100.00",
    Currency: "E-money",
    Descrpt: "New",
    Sponsor: "Shi",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
  {
    Txid: "111",
    Username: "sato",
    Plan: "Lite",
    Date: "28-05-2023",
    Amount: "100.00",
    Currency: "E-money",
    Descrpt: "New",
    Sponsor: "Shi",
    status: {
      color: "#28A745",
      text: "Paid",
    },
  },
];
