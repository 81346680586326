export const BannerUtils = [
  {
    id: 112,
    name: "Header 1",
    img: "View Here ",
    url: " http://ezugis.com/live-casino",
    type: "image ",
    enable: "Enable",
  },
  {
    id: 112,
    name: "Header 1",
    img: "View Here ",
    url: " http://ezugis.com/live-casino",
    type: "image ",
    enable: "Enable",
  },
  {
    id: 112,
    name: "Header 1",
    img: "View Here ",
    url: " http://ezugis.com/live-casino",
    type: "image ",
    enable: "Enable",
  },
  {
    id: 112,
    name: "Header 1",
    img: "View Here ",
    url: " http://ezugis.com/live-casino",
    type: "image ",
    enable: "Enable",
  },
  {
    id: 112,
    name: "Header 1",
    img: "View Here ",
    url: " http://ezugis.com/live-casino",
    type: "image ",
    enable: "Enable",
  },
  {
    id: 112,
    name: "Header 1",
    img: "View Here ",
    url: " http://ezugis.com/live-casino",
    type: "image ",
    enable: "Enable",
  },
  {
    id: 112,
    name: "Header 1",
    img: "View Here ",
    url: " http://ezugis.com/live-casino",
    type: "image ",
    enable: "Enable",
  },
];
