import React, { useState } from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { useNavigate } from "react-router-dom";
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
import csv from "../../assets/img/admin/svg/csv.svg";
import search from "../../assets/img/admin/svg/search.svg";

// utils
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useQuery } from "react-query";
import { privateRequest } from "../../config/axios.config";
import { TransactionTable } from "../../pages/admin/TransactionTable";
export const Transactions = () => {
  const [activepagination, setActivePagination] = useState(1);
  const [value, onChange] = useState(new Date());
  const [DateValue, setDateValue] = useState("");
  const [calenderState, setCalender] = useState(false);

  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    search: "",
    type: "",
    flag: "",
    startDate: "",
    endDate: "",
    status: "",
  });

  const { data, refetch } = useQuery(["transactions", filters], () =>
    privateRequest.get("admin/transactions/history", {
      params: { ...filters, page: activepagination, limit: 25 },
    })
  );
  const transactions = data?.data;
  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
  };

  const handleResetFilter = () => {
    setFilters({
      email: "",
      type: "",
      flag: "",
      startDate: "",
      endDate: "",
      status: "",
    });
    refetch();
  };

  const handlePaginationClick = (pageNumber) => {
    setActivePagination(pageNumber);

    setFilters((prevFilters) => ({ ...prevFilters, page: pageNumber }));
  };

  return (
    <div>
      <div className="flex items-start home admin-dashboard admin-dashboard-agent">
        {/* left area */}
        <Aside active={6} />

        <div className="flex-1">
          <Header heading="Transactions" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Transactions</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/");
                  }}
                >
                  Home
                </span>{" "}
                {/* / Transactions */}
              </button>
            </div>

            <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
              <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                <h1>Transactions History</h1>
                <div className="flex items-center gap-3">
                  <button className="h-11 outline-btn rounded px-4 flex items-center">
                    <img src={csv} alt="" className="mr-2" />
                    CSV
                  </button>
                  <button
                    className="h-11 rounded px-4"
                    onClick={(e) => {
                      navigate("/admin/transactions/create");
                    }}
                  >
                    Create New
                  </button>
                </div>
              </div>
              <div className="bottom-agent bottom-agent-page pt-6 pb-4 gap-2 flex items-end">
                <div className="input-container w-60">
                  <label htmlFor="email" className="mb-3 block">
                    Search
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <img src={search} alt="" />
                    <input
                      type="text"
                      placeholder="Search by email, TxID"
                      value={filters.search}
                      onChange={(e) =>
                        handleFilterChange("search", e.target.value)
                      }
                      className="flex-1 w-full h-full bg-transparent border-0 outline-none ml-2"
                    />
                  </div>
                </div>

                <div className="input-container w-60">
                  <label htmlFor="flag" className="mb-3 block">
                    Type
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <select
                      name="flag"
                      id="flag"
                      value={filters.flag}
                      onChange={(e) =>
                        handleFilterChange("flag", e.target.value)
                      }
                    >
                      <option value=""> Type</option>
                      <option value="deposit">Deposit</option>
                      <option value="staking">Staking</option>
                      <option value="Transfer">Transfer</option>
                      <option value="withdraw">Withdraw</option>
                      <option value="stakingReward">Staking Rewards</option>
                      <option value="direct_referral">Direct Referral</option>
                      <option value="royalty_bonus">Royalty Bonus</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>
                </div>

                <div className="input-container relative w-56 flex-1">
                  <label htmlFor="timePeriod" className="mb-3 block">
                    Time Period
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    {calenderState && (
                      <Calendar
                        onChange={(e) => {
                          onChange(e);

                          let FirstStart = String(e[0]);
                          let SecondStart = String(e[1]);

                          let FirstcorrectDay = `${FirstStart.split(" ")[2]}/${FirstStart.split(" ")[1]
                            }/${FirstStart.split(" ")[3]}`;

                          let SecondcorrectDay = `${SecondStart.split(" ")[2]
                            }/${SecondStart.split(" ")[1]}/${SecondStart.split(" ")[3]
                            }`;

                          console.log(FirstcorrectDay);

                          console.log(SecondcorrectDay);

                          setDateValue(
                            `${FirstcorrectDay} to ${SecondcorrectDay}`
                          );
                          setCalender(false);
                        }}
                        value={value}
                        selectRange={true}
                      />
                    )}

                    <input
                      onClick={(e) => {
                        setCalender(!calenderState);
                      }}
                      type="text"
                      placeholder="2023/09/19 to 2024/12/01"
                      value={DateValue}
                      // value={filters.startDate}
                      // onChange={(e) =>
                      //   handleFilterChange("startDate", e.target.value)
                      // }
                      className="flex-1 w-full h-full bg-transparent border-0 outline-none cursor-pointer"
                      readOnly
                    />
                  </div>
                </div>

                <div className="input-container w-32">
                  <label htmlFor="status" className="mb-3 block">
                    Status
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <select
                      name="status"
                      id="status"
                      value={filters.status}
                      onChange={(e) =>
                        handleFilterChange("status", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      <option value="pending">Pending</option>
                      <option value="active">Active</option>
                      <option value="completed">Completed</option>
                      <option value="processing">Processing</option>
                      <option value="approved">Approved</option>
                      <option value="confirmed">Confirmed</option>
                      <option value="declined">Declined</option>
                    </select>
                  </div>
                </div>

                <button className="rounded-lg px-5" onClick={handleResetFilter}>
                  Reset Filter
                </button>
              </div>

              <div className="table-wrapper">
                <TransactionTable
                  onPageChange={handlePaginationClick}
                  data={transactions}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
