import React from "react";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const generatePageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          className={`px-4 py-2 m-2 ${currentPage === i ? "border border-[#6FCF17] bg-[#6FCF17] text-white" : "!border !border-gray-400 text-white"} hover:bg-[#6FCF17] hover:text-black`}
          onClick={() => onPageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  return (
    <div className="flex pagination-table items-center justify-center mt-8">
      <button
        className="flex items-center mr-2"
        onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
        disabled={currentPage === 1}
      >
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* ... (path data) */}
        </svg>
        <p>Previous</p>
      </button>

      {generatePageNumbers()}

      <button
        className="flex items-center ml-2"
        onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
        disabled={currentPage === totalPages}
      >
        <p>Next</p>
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* ... (path data) */}
        </svg>
      </button>
    </div>
  );
};

export default Pagination;
