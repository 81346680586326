import React, { useState } from "react";
import { useQuery } from "react-query";
import { privateRequest } from "../../config/axios.config";

export const ChangeRole = ({ setactive, confirmRole }) => {
  const [role, setRole] = useState();

  const roles = useQuery("adminRoles", async () => {
    const res = await privateRequest.get("admin/roles/roles");
    return res.data;
  });

  return (
    <div className="delete-popup ">
      <span
        className="bg-popup"
        onClick={(e) => {
          setactive(false);
        }}
      ></span>
      <div className="flex flex-col rounded-lg py-10 px-20 items-center justify-center">
        <h1>Change Role</h1>
        <p className="my-3">
          Would you like to change the role of current user?
        </p>
        <div className="input-wrapper">
          <div className="input-container mt-3">
            <select
              className="w-full block my-5 px-6 py-2"
              onChange={(e) => setRole((prevRole) => e.target.value)}
            >
              <option value="">Select Role</option>
              {roles?.data?.data?.map((role) => (
                <option key={role?._id} value={role?._id}>
                  {role?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="buttons-wrapper gap-5 flex items-center justify-center">
          <button
            className="px-5 py-2"
            onClick={(e) => {
              confirmRole(role);
            }}
          >
            {" "}
            Update Role
          </button>
          <button
            className="px-5 py-2 del"
            onClick={(e) => {
              setactive(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
