import React, { useState } from "react";
// css
import "../../assets/css/admin/index.css";
import ButtonLoader from "../../components/ButtonLoader";
// components
import { useNavigate } from "react-router-dom";
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
import arrowdown from "../../assets/img/admin/svg/arrow-down.svg";
import search from "../../assets/img/admin/svg/search.svg";
// utils
import { useQuery } from "react-query";
import { privateRequest } from "../../config/axios.config";
import { MemberBalanceTable } from "./MemberBalanceReportList";

export const MemberBalanceReport = () => {
    const navigate = useNavigate();
    const [userId, setUserId] = useState("");

    const [searchInput, setSearchInput] = useState("");
    const [activepagination, setactivepagination] = useState(1); // State for active page
    const { data, refetch, isLoading } = useQuery(
        ["MemberBalanceReportApi", activepagination, searchInput],
        () =>
            privateRequest.get("admin/member-management/member-balance-report", {
                params: {
                    page: activepagination,
                    email: searchInput,
                },
            })
    );

    const handlePageChange = (newPage) => {
        setactivepagination(newPage);
    };

    const Members =
        data?.data?.members?.filter((el) => {
            const emailMatch = el.email
                .toLowerCase()
                .includes(searchInput.toLowerCase());
            const usercode = el.usercode?.includes(searchInput.toLowerCase());
            return emailMatch || usercode;
        }) ?? [];


    const resetFilter = (e) => {
        e.preventDefault();
        setSearchInput("");
        refetch();
    };


    return (
        <div>
            <div className="flex items-start home admin-dashboard  admin-dashboard-agent">
                {/* left area */}
                <Aside active="member-balance-report" />

                <div className="flex-1">
                    <Header heading="User Balance Report" />

                    <div className="body-area-admin py-8 px-6">
                        <div className="top-body-area flex items-center justify-between">
                            {/* <h1>Members List</h1> */}
                            <button className="flex items-center">
                                <img src={arrowback} alt="" className="mr-2" />
                                <span
                                    className="mr-1"
                                    onClick={(e) => {
                                        navigate("/");
                                    }}
                                >
                                    Home
                                </span>{" "}
                                {/* / Balance Report */}
                            </button>
                        </div>

                        <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
                            <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                                <h1>Members Balance List</h1>
                            </div>
                            <div className="bottom-agent bottom-agent-page   pt-6  pb-4  gap-2 flex items-end">
                                <div className="input-container flex-1">
                                    <label htmlFor="#" className="mb-3 block">
                                        Search
                                    </label>
                                    <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                                        <img src={search} alt="" />
                                        <input
                                            value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)}
                                            type="text"
                                            placeholder="Search by email"
                                            className="flex-1 h-full bg-transparent border-0 outline-none ml-2"
                                        />
                                        <img src={arrowdown} alt="" />
                                    </div>
                                </div>

                                <button onClick={resetFilter} className="rounded-lg px-5">
                                    Reset Filter
                                </button>
                            </div>

                            <div className="table-wrapper">
                                {isLoading ? (
                                    <>
                                        <ButtonLoader />
                                    </>
                                ) : (
                                    <>
                                        <MemberBalanceTable
                                            totalPages={data?.data?.totalPages}
                                            fetchData={refetch}
                                            data={Members}
                                            activepagination={activepagination}
                                            onPageChange={handlePageChange}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};
