export const Coin = [
  {
    heading: "BTC",
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
  },
  {
    heading: "ETH",
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
  },
  {
    heading: "USDT",
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
  },
  {
    heading: "BNB",
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
  },
  {
    heading: "XRP",
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/52.png",
  },
  {
    heading: "USDC",
    img: "https://cryptologos.cc/logos/thumbs/usd-coin.png?v=025",
  },
  {
    heading: "BUSD",
    img: "https://cryptologos.cc/logos/thumbs/binance-usd.png?v=025",
  },
  {
    heading: "SOL",
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
  },
  {
    heading: "ADA",
    img: "https://cryptologos.cc/logos/thumbs/cardano.png?v=025",
  },
  {
    heading: "DOGE",
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/74.png",
  },
  {
    heading: "TRX",
    img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png",
  },
];
