import moment from "moment";

/**
 * Converts a number into "0.00" format.
 * @param {number} number - The number to be converted.
 * @returns {string} The converted number in "0.00" format.
 */
export const convertToCurrency = (number, decimal, coin) => {
  if (number === undefined || number === null) {
    return "0.00";
  }

  const parsedNumber = parseFloat(number);
  if (isNaN(parsedNumber)) {
    return "Invalid number";
  }
  if (coin?.toLowerCase() === "usdt") return number.toFixed(2);
  return parsedNumber?.toFixed(decimal ?? 8) ?? number;
};

export const dateFormatter = (date) => {
  return moment.utc(date).format("YYYY-MM-DD HH:mm:ss");
};

export function getCoinName(coin) {
  switch (coin?.split("_")?.[0]?.toUpperCase()) {
    case "BTC":
      return "bitcoin";
    case "ETH":
      return "ethereum";
    case "USDT":
      return "tether";
    case "USDC":
      return "usd-coin";
    case "BNB":
      return "bnb";
    case "TRX":
      return "tron";
    default:
      return coin;
  }
}

export const isAmountValid = (amount) => {
  if (amount.match(/^\d*\.?\d*$/g) === null) return false;
  return true;
};
