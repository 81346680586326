import React from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Header } from "../../components/admin/Header";
import { Footer } from "../../components/admin/Footer";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";

// utils
import { Switch } from "../../components/settings/Switch";
import { CheckboxCustom } from "../../components/admin/CheckboxCustom";
import { useNavigate } from "react-router-dom";

export const CreateCMS = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex items-start home admin-dashboard">
        {/* left area */}
        <Aside active={12} />

        <div className="flex-1">
          <Header heading="CMS" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>CMS Pages</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/");
                  }}
                >
                  Home
                </span>{" "}
                /
                <span
                  className="mr-1 ml-1"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/admin/CMS");
                  }}
                >
                  CMS Pages
                </span>{" "}
                {/* / Create */}
              </button>
            </div>

            <div className="agent-wrapper create-agents mt-8  rounded-xl">
              <div className="top-agent  rounded-tl-xl  rounded-tr-xl py-7 px-6 top-agent-page flex items-center justify-between">
                <h1>CMS Pages</h1>
              </div>
              <div className="forms-wrapper px-6 py-6">
                <form action="#" className="grid grid-cols-2 gap-x-3 gap-y-6">
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Title <span>*</span>
                    </label>
                    <div className="input-container  mt-3">
                      <input type="text" placeholder="Enter your title" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Slug <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input type="text" placeholder="Enter your slug" />
                    </div>
                  </div>

                  <div className="input-wrapper  flex items-center  col-span-2">
                    <div className="flex-1">
                      <h1 className="title-checkbox">
                        Active / Inactive <span>*</span>
                      </h1>
                      <div className="flex items-center mt-2">
                        <div className="flex mr-3 items-center">
                          <Switch id="active-promo" />

                          <p className="ml-2 checkbox-para">
                            You can active it, if you want to publish.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="input-wrapper input-wrapper-textarea col-span-2">
                    <label htmlFor="">
                      Content (EN) <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <textarea
                        placeholder="Your content"
                        cols="30"
                        className="bg-transparent h-full w-full outline-none resize-none pl-6 pt-4"
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                  <div className="input-wrapper  flex items-center  col-span-2">
                    <div className="flex-1">
                      <h1 className="title-checkbox">
                        Active / Inactive <span>*</span>
                      </h1>
                      <div className="flex items-center mt-2">
                        <div className="flex mr-3 items-center">
                          <CheckboxCustom type="checkbox" id="enable-cms" />

                          <p className="ml-2 checkbox-para">
                            Enable / Disable CMS Page for Regisration Page
                            <span>*</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex buttons-wrapper items-center">
                      <button
                        className="h-11 px-4 rounded mr-3"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/admin/CMS");
                        }}
                      >
                        Submit
                      </button>
                      <button className="h-11 px-4 rounded outline-button">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
