import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { privateRequest } from "../../config/axios.config";
import { dateFormatter } from "../../utils";
import { Alert } from "./Alert";

export const PendingCryptoWithdrawalTable = ({ data, refetch }) => {
    const querClient = useQueryClient();
    const [transactionId, setTransactionId] = useState(null);
    const [reason, setReason] = useState(null);
    const [approvePopup, setApprovePopup] = useState(false);
    const [delpopup, setdelpopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allTransactionId, setAllTransactionId] = useState([]);
    const [showApproveButton, setShowApproveButton] = useState(false);
    const [selectedCheckbox, setSelectedCheckbox] = useState({});
    const approveWithdrawal = useMutation(
        async (transactionId) => {
            try {
                setLoading(true);
                const res = await privateRequest.post(
                    `admin/crypto-withdrawal/approved-withdraw`, {
                    transactionId
                }
                );

                // Check if the response indicates an error
                if (res.data && res.data.success === false) {
                    throw new Error(res.data.message);
                }
                setLoading(false);
                // If the request is successful, return the data
                return res?.data;
            } catch (error) {
                throw error;
            }
        },
        {
            onSuccess: (data) => {
                console.log("Request approved successfully:", data);
                refetch();
            },
            onError: (error) => {
                console.error("Error in approveWithdrawal mutation:", error);

                // Display an appropriate error message in the toast
                const errorMessage = error?.message || "Error approving withdrawal";
                toast.error(errorMessage);
            },
        }
    );
    const declineWithdrawal = useMutation(
        (payload) =>
            privateRequest.post(
                `admin/withdrawal/decline-withdrawal-request`,
                payload
            ),
        refetch()
    );

    const handelApproval = (id) => {
        setApprovePopup(true);
        setTransactionId(id);
    };

    const handelDelete = (id) => {
        setdelpopup(true);
        setTransactionId(id);
    };

    const submitApprove = async () => {
        try {
            toast.promise(approveWithdrawal.mutateAsync(transactionId), {
                pending: "Approving withdrawal...",
                success: "Withdrawal approved successfully!",
                error: "Error during withdrawal approval",
            });
        } catch (error) {
            console.error("Error in submitApprove:", error);
            toast.error("Error during withdrawal approval");
        }
    };

    const submitDecline = async () => {
        const payload = {
            requestId: transactionId,
            reason: reason,
        };
        try {
            toast.promise(declineWithdrawal.mutateAsync(payload), {
                pending: "Declining withdrawal...",
                success: () => {
                    querClient.invalidateQueries("withdrawals");
                    return "Withdrawal declined successfully!";
                },
                error: "Error decline withdrawal",
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        const isChecked = e.target.checked;

        if (isChecked) {
            setAllTransactionId(prev => [...prev, value]);
        } else {
            setAllTransactionId(prev => prev.filter(item => item !== value));
        }
    }

    const approveSelectedWithdrawal = useMutation(
        async (data) => {
            try {
                setLoading(true);
                const res = await privateRequest.post(
                    `admin/crypto-withdrawal/approved-selected-withdrawal-requests`, data
                );

                // Check if the response indicates an error
                if (res.data && res.data.success === false) {
                    throw new Error(res.data.message);
                }
                setLoading(false);
                // If the request is successful, return the data
                return res?.data;
            } catch (error) {
                throw error;
            }
        },
        {
            onSuccess: (data) => {
                console.log("Request approved successfully:", data);
                refetch();
            },
            onError: (error) => {
                console.error("Error in approveWithdrawal mutation:", error);

                // Display an appropriate error message in the toast
                const errorMessage = error?.message || "Error approving withdrawal";
                toast.error(errorMessage);
            },
        }
    );

    const approveSelectedItems = () => {
        const data = {
            transactionIds: allTransactionId
        };

        toast.promise(approveSelectedWithdrawal.mutateAsync(data), {
            pending: "Approving withdrawals...",
            success: "Withdrawal approved successfully!",
            error: "Error during withdrawal approval",
        });
    }

    useEffect(() => {
        if (allTransactionId.length > 0) {
            setShowApproveButton(true);
        } else {
            setShowApproveButton(false);
        }
    }, [allTransactionId])

    useEffect(() => {
        const allSelected = Object.values(selectedCheckbox).every(checked => checked);
        console.log(allSelected);
        const updateCheckboxes = {};
        for (const key in selectedCheckbox) {
            updateCheckboxes[key] = !allSelected;
        }
        setSelectedCheckbox(updateCheckboxes);
    }, [data]);
    return (
        <>
            {approvePopup && (
                <Alert
                    setactive={() => setApprovePopup(false)}
                    confirmSubadminBlock={(value) => {
                        if (value) {
                            submitApprove();
                            setApprovePopup(false);
                        }
                    }}
                    title="Confirm Withdrawal Approval"
                    text="You are about to approve a withdrawal request. This will initiate the transfer of funds. Please confirm to proceed with the approval."
                    buttonText="Approve"
                />
            )}
            {delpopup && (
                <Alert
                    confirmReason={(value) => {
                        setReason(value);
                    }}
                    needReason={true}
                    setactive={() => setdelpopup(false)}
                    confirmSubadminBlock={(value) => {
                        if (value) {
                            submitDecline();
                            setdelpopup(false);
                        }
                    }}
                    title="Confirm Withdrawal Decline"
                    text="This action cannot be undone. Please confirm that you wish to decline the withdrawal request and provide a reason to ensure clear communication with the user."
                    buttonText="Decline"
                />
            )}
            {showApproveButton && <>
                <div className="mb-5">
                    <button
                        onClick={approveSelectedItems}
                        // disabled={loading}
                        className="mr-1 bg-[#6FCF17] text-white px-3 py-1 rounded-sm"
                    >
                        Approve Selected
                    </button>
                </div>
            </>
            }
            <table className="w-full AgentTable">
                <thead>
                    <th >

                    </th>
                    <th>TXID</th>
                    <th>Timestamp</th>
                    <th>User </th>
                    <th>Payment Method </th>
                    <th>Amount</th>
                    <th>Wallet Address</th>
                    <th>Payment Currency Amount</th>
                    <th>Network</th>
                    {/* <th>Note</th> */}
                    <th>Status</th>
                    <th>Actions</th>
                </thead>
                <tbody>
                    {data?.map((row) => (
                        <tr>
                            <td>
                                <input type="checkbox" value={row?._id} onChange={handleCheckboxChange} className="mx-2" />
                            </td>
                            <td>{row?.transactionReference}</td>
                            <td>{dateFormatter(row?.createdAt)}</td>
                            <td>{row?.userId?.email}</td>
                            <td>{row?.meta?.paymentMethod}</td>

                            <td
                                style={{
                                    color: row?.transactionType === "debit" ? "red" : "green",
                                }}
                            >
                                {row?.transactionType === "debit" && "- "}
                                {row?.transactionType === "credit" && "+ "}
                                {row?.amount?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td>{row?.meta?.walletAddress}</td>

                            <td>
                                {parseFloat(row?.meta?.paymentCurrencyAmount)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                {row?.meta?.paymentCurrency?.toUpperCase()}
                            </td>
                            <td>
                                {row?.meta?.network}
                            </td>
                            {/* <td>{row?.note}</td> */}
                            <td className="px-3">{row?.status?.toUpperCase()}</td>

                            <td>
                                <div className="flex items-center gap-2">
                                    {/* <Link
                                        className="mr-1 bg-[#6FCF17] text-white px-3 py-1 rounded-sm"
                                        to={`/admin/withdrawls/details/${row?._id}`}
                                    >
                                        View
                                    </Link> */}
                                    {row?.status === "pending" && (
                                        <>
                                            <button
                                                disabled={loading}
                                                onClick={() => handelApproval(row?._id)}
                                                className="mr-1 bg-[#6FCF17] text-white px-3 py-1 rounded-sm"
                                            >
                                                Approve
                                            </button>
                                            {/* <button
                                                disabled={loading}
                                                onClick={() => handelDelete(row?._id)}
                                                className="mr-1 bg-[#fd3939] text-white px-3 py-1 rounded-sm"
                                            >
                                                Decline
                                            </button> */}
                                        </>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>


        </>
    );
};
