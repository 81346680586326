export const state = {
  series: [
    {
      name: "Total Deposits",
      data: ["12", "11", "8", "9", "10", "9", "9", "7"],
    },
  ],
  options: {
    chart: {
      type: "area",

      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    // colors: ["#3EFFBB"],
    colors: ["#6FCF17"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    labels: [
      "12:00",
      "12:30",
      "01:00",
      "01:30",
      "02:00",
      "02:30",
      "03:00",
      "03:30",
    ],
    yaxis: {
      labels: {
        style: {
          fontSize: 10,
          colors: "#959699",
          fontFamily: "Arabic Pro Reg",
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: 10,
          colors: "#959699",
          fontFamily: "Arabic Pro Reg",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      borderColor: "#3C403F",
    },
    legend: {
      horizontalAlign: "left",
    },
  },
};

export const state2 = {
  series: [
    {
      name: "Total Active Players",
      data: ["9", "28", "59", "70", "75", "95", "105", "111", "122"],
    },
  ],
  options: {
    chart: {
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    colors: ["#007BFF", "#007BFF"],
    dataLabels: {
      enabled: false,
    },

    stroke: {
      curve: "smooth",
    },
    labels: [
      "12:00",
      "12:30",
      "01:00",
      "01:30",
      "02:00",
      "02:30",
      "03:00",
      "03:30",
    ],
    yaxis: {
      labels: {
        style: {
          fontSize: 10,
          colors: "#959699",
          fontFamily: "Arabic Pro Reg",
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: 10,
          colors: "#959699",
          fontFamily: "Arabic Pro Reg",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      borderColor: "#3C403F",
    },
    legend: {
      horizontalAlign: "left",
    },
  },
};
