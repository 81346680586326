import { useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";

export const useDashboardStatistics = (status) =>
    useQuery(["withdrawals", status], () =>
        privateRequest.get(
            `admin/dashboard/statistics`
        )
    );

export const useDashboardHistoryTable = () =>
    useQuery(["histories"], () =>
        privateRequest.get(
            `admin/dashboard/histories`
        )
    );
