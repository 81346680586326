import React from "react";

export const Switch = ({ id, activeSwitch = null, onChange }) => {
  return (
    <div className="Switch">
      <input
        type="checkbox"
        onChange={onChange}
        id={id}
        defaultChecked={activeSwitch}
      />
      <label htmlFor={id} className="flex items-center">
        <span></span>
      </label>
    </div>
  );
};
