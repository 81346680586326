import React from "react";

export default function ButtonLoader() {
  return (
    <>
      <div className="flex items-center justify-center">
        <div className="loader animate-spin"></div>
      </div>
    </>
  );
}
