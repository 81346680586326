export const BankUtils = [
  {
    id: 112,
    bankname: "SBI",
    ifscnumber: "SBIN000000",
    accountname: "Rahul Sadhu",
    accountnumber: "7896541230",
    lastupdate: "500 PHP",
    active: "Rejected",
    created: "N/A",
    updated: "07-07-2023 10:39 AM",
    actions: "Approve",
  },
];
