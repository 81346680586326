export const PlayersUtil = [
  {
    id: 112,
    username: "username1128",
    nickname: "Brooklyn Simmons",
    email: "sara.cruz@example.com",
    fname: "Bessie",
    lname: "Fox",
    totalbalance: "0.00 PHP",
    status: "No",
    kyc: "No",
  },
  {
    id: 112,
    username: "username1128",
    nickname: "Brooklyn Simmons",
    email: "sara.cruz@example.com",
    fname: "Bessie",
    lname: "Fox",
    totalbalance: "0.00 PHP",
    status: "No",
    kyc: "No",
  },
  {
    id: 112,
    username: "username1128",
    nickname: "Brooklyn Simmons",
    email: "sara.cruz@example.com",
    fname: "Bessie",
    lname: "Fox",
    totalbalance: "0.00 PHP",
    status: "No",
    kyc: "No",
  },
  {
    id: 112,
    username: "username1128",
    nickname: "Brooklyn Simmons",
    email: "sara.cruz@example.com",
    fname: "Bessie",
    lname: "Fox",
    totalbalance: "0.00 PHP",
    status: "No",
    kyc: "No",
  },
  {
    id: 112,
    username: "username1128",
    nickname: "Brooklyn Simmons",
    email: "sara.cruz@example.com",
    fname: "Bessie",
    lname: "Fox",
    totalbalance: "0.00 PHP",
    status: "No",
    kyc: "No",
  },
  {
    id: 112,
    username: "username1128",
    nickname: "Brooklyn Simmons",
    email: "sara.cruz@example.com",
    fname: "Bessie",
    lname: "Fox",
    totalbalance: "0.00 PHP",
    status: "No",
    kyc: "No",
  },
  {
    id: 112,
    username: "username1128",
    nickname: "Brooklyn Simmons",
    email: "sara.cruz@example.com",
    fname: "Bessie",
    lname: "Fox",
    totalbalance: "0.00 PHP",
    status: "No",
    kyc: "No",
  },
  {
    id: 112,
    username: "username1128",
    nickname: "Brooklyn Simmons",
    email: "sara.cruz@example.com",
    fname: "Bessie",
    lname: "Fox",
    totalbalance: "0.00 PHP",
    status: "No",
    kyc: "No",
  },
];
