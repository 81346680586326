export function formatDateToTimestamp(input) {
    let date = input;
  
    // Convert string input to Date object
    if (typeof input === 'string') {
      date = new Date(input);
    }
  
    // Check if date is a valid Date object
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      throw new Error('Invalid date input');
    }
  
    const pad = (number) => (number < 10 ? `0${number}` : number);
  
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-indexed
    const day = pad(date.getDate());
  
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
  
    return `${year}-${month}-${day}`;
    // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }