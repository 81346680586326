export const MenuConfigUtils = [
  {
    id: 112,
    name: "Casino",
    path: "/casino",
  },
  {
    id: 112,
    name: "Live Casino",
    path: "/live/casino",
  },
  {
    id: 112,
    name: "Promotino",
    path: "/promotion",
  },
  {
    id: 112,
    name: "Casino",
    path: "/casino",
  },
];
