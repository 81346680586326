import React from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Header } from "../../components/admin/Header";
import { Footer } from "../../components/admin/Footer";

// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";

// utils
import { NotificationTable } from "./NotificationTable";
import { NotificationUtil } from "../../utils/NotificationUtil";
import { useNavigate } from "react-router-dom";

export const Notifications = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex items-start home admin-dashboard admin-dashboard-agent">
        {/* left area */}
        <Aside />

        <div className="flex-1">
          <Header heading="Notifications" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Notifications</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/");
                  }}
                >
                  Home
                </span>
                / Notifications
              </button>
            </div>

            <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
              <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                <h1>Notifications</h1>
              </div>
              <div className="bottom-agent bottom-agent-page  pt-6   gap-2 flex items-end"></div>
              <div className="table-wrapper">
                <NotificationTable data={NotificationUtil} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
