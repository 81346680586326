import React, { useContext, useEffect, useRef } from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Header } from "../../components/admin/Header";
import { Footer } from "../../components/admin/Footer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import copy from "copy-to-clipboard";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";

// utils
import { Coin } from "../../utils/coins";
import { PayDropdown } from "../../components/admin/PayDropdown";
import qr from "../../assets/img/admin/svg/qr.svg";
import { useWallet } from "../../contexts/WalletContext";
import formatCurrencyName from "../../utils/wallet";
export const WalletDeposite = () => {
  const navigate = useNavigate();
  const refText = useRef(null);
  const { currency } = useParams();
  const { wallet } =useWallet();

  // Find the specific wallet using the walletAddress
  const specificWallet = wallet.find(w => w.currency === currency) || {};

 
  return (
    <div>
      <div className="flex items-start home admin-dashboard  admin-dashboard-agent">
        {/* left area */}
        <Aside active="wallet/1" />

        <div className="flex-1">
          <Header heading="Agents" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Wallets</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/");
                  }}
                >
                  Home
                </span>{" "}
                / Deposit
              </button>
            </div>

            <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
              <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                <h1>Deposit</h1>
              </div>
              <div className="bottom-agent bottom-agent-page   pt-6    gap-2 flex items-end"></div>

              <div className="">
                <div className="mb-6">
                  <p className="text-white">
                    {formatCurrencyName(specificWallet.currency) || ""}
                  </p>
                  {/* <label
                    htmlFor="#"
                    className="text-[18px] font-arabicreg text-[#fff] mb-3 block"
                  >
                    Select Coin
                  </label>
                  <PayDropdown
                    stake
                    classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
                    dollar={Coin[0].img}
                    heading={Coin[0].heading}
                    list={currency}
                  /> */}
                </div>
                <div>
                  <label
                    htmlFor="#"
                    className="text-[18px] font-arabicreg text-[#fff] mb-3 block"
                  >
                    Deposit Address
                  </label>

                  <div className="qr-container p-5 bg-[#111010] border-[1px] border-[#3C403F] mt-6 flex items-center rounded-lg">
                    <div className="qr-wrapper mr-6">
                      <img src={qr} alt="" />
                    </div>
                    <div className="presentation flex-1">
                      <h1 className="text-[20px] text-[#AEB2B1] mb-[5px]">
                        Address
                      </h1>
                      <div className="copy-area w-full flex items-center">
                        <input
                          ref={refText}
                          type="text"
                          className="w-full flex-1 bg-transparent border-0 outline-none text-[12px] font-arabicmed text-[#fff]"
                          value={specificWallet.address || ""}
                        />

                        <button
                          className="text-[12px] text-[#111010] bg-gradient-to-br from-[#9BE402] to-[#1FA83C] h-8
                           w-[104px] rounded font-bold"
                          onClick={() => {
                            copy(specificWallet.address || "");
                            toast.success("Deposit Address Copied");
                          }}
                        >
                          Copy Address
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
