import React from "react";
import { Link } from "react-router-dom";
import close from "../../assets/img/admin/svg/close.svg";
import successfull from "../../assets/img/admin/svg/successfull.svg";
export const TransferDone = ({ settransfer, email }) => {
  return (
    <div className="welcome-ref-popup h-[100vh] w-full fixed top-0 left-0 flex items-center justify-center z-[99999] ">
      <span
        className="h-[100vh] w-full fixed top-0 left-0 -z-10"
        onClick={(e) => {
          settransfer(false);
        }}
      ></span>
      <div className="!bg-[#252525] border-[1px] border-[#3C403F] w-[70vw] h-[90vh] rounded-xl lg:w-full lg:h-full relative flex flex-col lg:!rounded-none">
        <div className="flex px-8 items-center justify-between h-[80px] rounded-tl-xl rounded-tr-xl bg-[#2E2E2E] lg:!rounded-none">
          <h1 className="text-[#fff] text-[24px] font-arabicmed">
            Transfer Balance
          </h1>
          <img
            src={close}
            alt=""
            className="cursor-pointer"
            onClick={(e) => {
              settransfer(false);
            }}
          />
        </div>

        <div className="content-area flex w-[629px] text-center flex-col items-center justify-center  mx-auto pb-[10vh] flex-1 md:w-[90%]">
          <img src={successfull} alt="" />
          <h1 className="text-[#fff] font-arabicmed mt-10 mb-6 text-[24px]">
            Transfer Successful
          </h1>
          <p className="text-[20px] font-arabicmed text-[#AEB2B1]">
            Your Transfer already done with{" "}
            <a href="#" className="text-[#fff]">
              {email}
            </a>
          </p>
          <div className="flex items-center md:flex-col gap-6 w-full mt-10">
            <Link
              to="/"
              className="w-full h-[50px] text-[18px] font-arabicbold text-white border-[1px] border-[#3C403F] bg-[#252525] rounded-lg flex items-center justify-center"
            >
              Go to Transfer Balance
            </Link>
            <Link
              to="/admin/wallet/tranfer/history"
              className="w-full h-[50px] text-[18px] font-arabicbold text-[#111010] border-[1px] border-[#3C403F] bg-[#6FCF17]  rounded-lg  flex items-center justify-center"
            >
              See Transfer History
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
