import deleteImg from "../../assets/img/admin/svg/delete.svg";
import link from "../../assets/img/admin/svg/link.svg";
import { Link, useNavigate } from "react-router-dom";
import edit from "../../assets/img/admin/svg/edit.svg";
import { Popup } from "./Popup";
import { useState } from "react";
export const BannerTable = ({ data }) => {
  const navigate = useNavigate();
  const [delpopup, setdelpopup] = useState(false);
  return (
    <>
      {delpopup && <Popup setactive={setdelpopup} />}

      <table className="w-full AgentTable">
        <thead>
          <th>ID</th>
          <th>Name</th>
          <th>Image/Video URL</th>
          <th>Redirect URL</th>
          <th>Type</th>
          <th>Enabled </th>
          <th>Action</th>
        </thead>
        <tbody>
          {data.map((EachData) => (
            <tr>
              <td>{EachData.id}</td>
              <td>{EachData.name}</td>
              <td>
                <Link href="" className="flex items-center">
                  {EachData.img}
                  <img src={link} alt="" className="ml-2" />
                </Link>
              </td>
              <td>
                <Link href={EachData.url} style={{ color: "#007BFF" }}>
                  {EachData.url}
                </Link>
              </td>
              <td>{EachData.type}</td>
              <td>{EachData.enable}</td>

              <td>
                <div className="flex items-center">
                  <button
                    className="mr-1"
                    onClick={(e) => {
                      navigate("/admin/admin/banner/edit");
                    }}
                  >
                    <img src={edit} alt="" />
                  </button>
                  <button
                    onClick={(e) => {
                      setdelpopup(true);
                    }}
                  >
                    <img src={deleteImg} alt="" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
