import React, { useState } from "react";
// css
import "../../assets/css/admin/index.css";

// components
import ReactApexChart from "react-apexcharts";
import { ActivityBox } from "../../components/admin/ActivityBox";
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
import per_country from "../../assets/img/admin/svg/per_country.svg";

// utils
import {
  useDashboardHistoryTable,
  useDashboardStatistics,
} from "../../queries/dashboard";
import { state, state2 } from "../../utils/ChartFeatured";
import { DashboardHistoryTable } from "./DashboardHistoryTable";
export const Dashboard = () => {
  const { data, refetch } = useDashboardStatistics();
  const { data: historiesData, refetch: historyRefetch } =
    useDashboardHistoryTable();
  const statistics = data?.data?.data?.statistics;
  const histories = historiesData?.data?.data?.history;

  const [activeTab, setactiveTab] = useState(null);
  function addCommasToStringNumber(number) {
    if (number === null || number === undefined) {
      return "0.00";
    }

    const value = parseFloat(number)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  }

  function formatFlags(data) {
    if (!data) {
      return null;
    }

    const formattedData = {
      active: addCommasToStringNumber(data.active),
      confirmed: addCommasToStringNumber(data.confirmed),
      pending: addCommasToStringNumber(data.pending),
      processing: addCommasToStringNumber(data.processing),
      terminated: addCommasToStringNumber(data.terminated),
    };

    return formattedData;
  }
  return (
    <div>
      <div className="flex items-start home admin-dashboard">
        {/* left area */}
        <Aside active={1} />

        <div className="flex-1">
          <Header heading="Home" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Dashboard</h1>

              {/* <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                Home
              </button> */}
            </div>

            <div className="charts-wrapper grid grid-cols-3 gap-4 mt-8">
              <div className="chart py-4 rounded-xl">
                <div className="chart-top px-4 pb-2 ">
                  <h1>Total Deposits</h1>
                </div>
                <div>
                  <ReactApexChart
                    options={state.options}
                    series={state.series}
                    type="area"
                    height={180}
                  />
                </div>
                <div className="chart-bottom px-4 flex items-center justify-between">
                  <div className="badge flex items-center">
                    <span className="w-2 h-2 rounded-full block mr-1"></span>
                    <p>Crypto</p>
                  </div>
                  <div className="badge flex items-center">
                    <span className="w-2 h-2 rounded-full block mr-1"></span>
                    <p>Fiat</p>
                  </div>
                  <div className="badge flex items-center">
                    <span className="w-2 h-2 rounded-full block mr-1"></span>
                    <p>Usdt</p>
                  </div>
                  <div className="badge flex items-center">
                    <span className="w-2 h-2 rounded-full block mr-1"></span>
                    <p>USD</p>
                  </div>
                  <div className="badge flex items-center">
                    <span className="w-2 h-2 rounded-full block mr-1"></span>
                    <p>BTC</p>
                  </div>
                  <div className="badge flex items-center">
                    <span className="w-2 h-2 rounded-full block mr-1"></span>
                    <p>PHP</p>
                  </div>
                  <div className="badge flex items-center">
                    <span className="w-2 h-2 rounded-full block mr-1"></span>
                    <p>ETH</p>
                  </div>
                </div>
              </div>
              <div className="chart  py-4 rounded-xl">
                <div className="chart-top px-4 pb-2">
                  <h1>Total Active Players</h1>
                </div>
                <div>
                  <ReactApexChart
                    options={state2.options}
                    series={state2.series}
                    type="area"
                    height={180}
                  />
                </div>
                <div className="chart-bottom px-4  flex items-center justify-center">
                  <p>All Players</p>
                </div>
              </div>
              <div className="chart chartbgchange relative  p-4 rounded-xl">
                <div className="chart-top pb-2">
                  <h1>Total Players per Country</h1>
                </div>
                <img
                  src={per_country}
                  className="percountry-img mx-auto mt-6"
                  alt=""
                />
                <div className="chart-bottom mt-4 flex items-center justify-center">
                  <p>Philippine 59 players</p>
                </div>
              </div>
            </div>

            <div className="activity-row p-4 rounded-xl mt-8">
              <div className="top-area">
                <h1>Statistics</h1>
              </div>

              <div className="grid grid-cols-3 mt-5 gap-3">
                <ActivityBox
                  title="Total Staking Subscription"
                  total={addCommasToStringNumber(
                    statistics?.grandTotalFlags?.stakingSubscription
                  )}
                  type="staking"
                  data={formatFlags(statistics?.flags?.stakingSubscription)}
                />

                <ActivityBox
                  title="Total GRR Paid"
                  total={addCommasToStringNumber(
                    statistics?.grandTotalFlags?.stakingReward
                  )}
                  type="grr"
                  data={formatFlags(statistics?.flags?.stakingReward)}
                />

                <ActivityBox
                  title="Total Withdrawal"
                  total={addCommasToStringNumber(
                    statistics?.grandTotalFlags?.withdraw
                  )}
                  type="withdrawal"
                  data={formatFlags(statistics?.flags?.withdraw)}
                />

                <ActivityBox
                  title="Total Withdrawal Fee Earned"
                  total={addCommasToStringNumber(
                    statistics?.grandTotalFlags?.withdrawal_fee
                  )}
                  type="withdrawal_fee"
                  data={formatFlags(statistics?.flags?.withdrawal_fee)}
                />

                <ActivityBox
                  title="Total Royalty Paid"
                  total={addCommasToStringNumber(
                    statistics?.grandTotalFlags?.royalty_bonus
                  )}
                  type="royalty"
                  data={formatFlags(statistics?.flags?.royalty_bonus)}
                />

                <ActivityBox
                  title="Total Direct Referral Commission Paid"
                  total={addCommasToStringNumber(
                    statistics?.grandTotalFlags?.direct_referral
                  )}
                  type="commission_earned"
                  data={formatFlags(statistics?.flags?.direct_referral)}
                />
              </div>
            </div>

            {/* <div className="agent-wrapper agent-wrapper-dashboard mt-8 p-6 rounded-xl">
              <div className="top-agent flex items-center justify-between">
                <h1>Agent</h1>
                <p>2023-09-14 / 23:54:12</p>
              </div>
              <div className="bottom-agent mt-3  gap-2 flex items-center">
                <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                  <img src={search} alt="" />

                  <input
                    type="text"
                    placeholder="Search Agent"
                    className="flex-1 h-full bg-transparent border-0 outline-none ml-2"
                  />
                  <img src={arrowdown} alt="" />
                </div>

                <button
                  className={`rounded-lg px-5 ${activeTab === 1 && "active"}`}
                  onClick={(e) => {
                    setactiveTab(1);
                  }}
                >
                  Daily
                </button>
                <button
                  className={`rounded-lg px-5 ${activeTab === 2 && "active"}`}
                  onClick={(e) => {
                    setactiveTab(2);
                  }}
                >
                  Weekly
                </button>
                <button
                  className={`rounded-lg px-5 ${activeTab === 3 && "active"}`}
                  onClick={(e) => {
                    setactiveTab(3);
                  }}
                >
                  Monthly
                </button>
                <button
                  className={`rounded-lg px-5 ${activeTab === 4 && "active"}`}
                  onClick={(e) => {
                    setactiveTab(4);
                  }}
                >
                  Custom
                </button>
                <button
                  className="rounded px-5"
                  onClick={(e) => {
                    setactiveTab(null);
                  }}
                >
                  Reset Filter
                </button>
              </div>
            </div> */}

            {/* <div className="activity-row p-4 rounded-xl mt-8">
              <div className="top-area">
                <h1>Financial</h1>
              </div>

              <div className="grid grid-cols-3 mt-5 gap-3">
                <ActivityBox />
                <ActivityBox />
                <ActivityBox />
              </div>
            </div>
            <div className="activity-row p-4 rounded-xl mt-8">
              <div className="top-area">
                <h1>Gaming Activity</h1>
              </div>

              <div className="grid grid-cols-3 mt-5 gap-3">
                <ActivityBox />
                <ActivityBox />
                <ActivityBox />
              </div>
            </div> */}

            {/* histories */}
            <div className="p-4 rounded-xl mt-8">
              <div className="top-area text-white">
                <h1>History</h1>
              </div>
              <div className="grid grid-cols-1 mt-5 gap-3">
                <DashboardHistoryTable
                  title="Staking Subscriptions"
                  type="staking"
                  data={histories?.stakingSubscriptionHistory}
                />
              </div>
              <div className="grid grid-cols-1 mt-5 gap-3">
                <DashboardHistoryTable
                  title="Withdrawals  Request"
                  type="withdrawalRequestHistory"
                  data={histories?.withdrawalRequestHistory}
                />
              </div>
              <div className="grid grid-cols-1 mt-5 gap-3">
                <DashboardHistoryTable
                  title="Transactions"
                  type="transactionHistory"
                  data={histories?.transactionHistory}
                />
              </div>{" "}
              <div className="grid grid-cols-1 mt-5 gap-3">
                {" "}
                <DashboardHistoryTable
                  title="GRR's Paid"
                  type="dailyGRRPaidHistory"
                  data={histories?.dailyGRRPaidHistory}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
