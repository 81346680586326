import React, { useEffect } from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { useNavigate } from "react-router-dom";
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";

// utils
import ButtonLoader from "../../components/ButtonLoader";
import { useWallet } from "../../contexts/WalletContext";
import { useGetAdminWallets } from "../../queries/wallet";
import { CashWalletTable } from "./CashWalletTable";
import { CryptoWalletTable } from "./CryptoWalletTable";
export const AdminWallet = () => {
  const navigate = useNavigate();
  const { updateWallet } = useWallet();

  const { data: adminWalletData, isLoading, error } = useGetAdminWallets();
  useEffect(() => {
    if (adminWalletData && !isLoading) {
      // Assuming adminWalletData contains the relevant wallet info
      updateWallet(adminWalletData?.data?.wallets);
    }
  }, [adminWalletData, isLoading, updateWallet]);


  return (
    <div>
      <div className="flex items-start home admin-dashboard  admin-dashboard-agent">
        {/* left area */}
        <Aside active="wallet/1" />

        <div className="flex-1">
          <Header heading="Agents" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Wallets</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/");
                  }}
                >
                  Home
                </span>{" "}
                {/* / Admin wallets */}
              </button>
            </div>

            <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
              <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                <h1>Admin wallets</h1>
              </div>
              <div className="bottom-agent bottom-agent-page   pt-6    gap-2 flex items-end"></div>

              <div className="grid grid-cols-2 gap-10">
                <div className="table-wrapper">
                  <h1 className="text-white font-arabicreg text-[14px] mb-3">
                    Crypto wallets
                  </h1>
                  {isLoading ? (
                  <>
                    <ButtonLoader />
                  </>
                ) : (
                 
                  <CryptoWalletTable wallets={adminWalletData?.data?.wallets} />
                )}

                </div>
                <div className="table-wrapper">
                  <h1 className="text-white font-arabicreg text-[14px] mb-3">
                    Cash wallets
                  </h1>
                  <CashWalletTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
