export const CredentialsUtils = [
  {
    id: 112,
    key: "APP_TWILO_ACCOUNT_SID",
    value: "AC355769162622316f6e135261a8a97d1e",
    description: "Testign",
  },
  {
    id: 112,
    key: "APP_TWILO_ACCOUNT_SID",
    value: "AC355769162622316f6e135261a8a97d1e",
    description: "Testign",
  },
  {
    id: 112,
    key: "APP_TWILO_ACCOUNT_SID",
    value: "AC355769162622316f6e135261a8a97d1e",
    description: "Testign",
  },
  {
    id: 112,
    key: "APP_TWILO_ACCOUNT_SID",
    value: "AC355769162622316f6e135261a8a97d1e",
    description: "Testign",
  },
  {
    id: 112,
    key: "APP_TWILO_ACCOUNT_SID",
    value: "AC355769162622316f6e135261a8a97d1e",
    description: "Testign",
  },
  {
    id: 112,
    key: "APP_TWILO_ACCOUNT_SID",
    value: "AC355769162622316f6e135261a8a97d1e",
    description: "Testign",
  },
];
