export const CMSUtil = [
  {
    id: "01",
    title: "Terms & Conditions",
    slug: "terms-conditions",
    active: "Yes",
    registration: "Enable",
    createdBy: "03 January 2023",
  },
  {
    id: "02",
    title: "Contact Us",
    slug: "contact-us",
    active: "Yes",
    registration: "Enable",
    createdBy: "15 January 2023",
  },
  {
    id: "03",
    title: "Privacy Policy",
    slug: "privacy-policy",
    active: "Yes",
    registration: "Enable",
    createdBy: "21 January 2023",
  },
];
