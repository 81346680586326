import React from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { useNavigate } from "react-router-dom";
import { Aside } from "../../components/admin/Aside";
import { Footer } from "../../components/admin/Footer";
import { Header } from "../../components/admin/Header";
// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
import arrowdown from "../../assets/img/admin/svg/arrow-down.svg";

// utils

import { useQuery } from "react-query";
import { privateRequest } from "../../config/axios.config";
import { SubAdminTable } from "./SubAdminTable";

export const Subadmins = () => {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");

  const { data, refetch } = useQuery("subadmin", () =>
    privateRequest.get("admin/management/subadmin-lists")
  );

  const subAdmins =
    data?.data?.data?.filter(
      (el) =>
        el.email.includes(search) || el.username?.toLowerCase().includes(search)
    ) ?? [];

  const resetFilter = () => {
    setSearch("");
    refetch();
  }

  return (
    <div>
      <div className="flex items-start home admin-dashboard admin-dashboard-agent">
        {/* left area */}
        <Aside active="subadmin/1" />

        <div className="flex-1">
          <Header heading="Sub admins" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Sub admins</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/");
                  }}
                >
                  Home
                </span>{" "}
                {/* / List of sub admin */}
              </button>
            </div>

            <div className="agent-wrapper mt-8 p-6 pt-8 rounded-xl">
              <div className="top-agent pb-8 top-agent-page flex items-center justify-between">
                <h1>List of sub admin</h1>
                <button
                  className="h-11 rounded px-4"
                  onClick={(e) => {
                    navigate("/admin/sub-admin/create");
                  }}
                >
                  Create New
                </button>
              </div>
              <div className="bottom-agent bottom-agent-page  pt-6  pb-4  gap-2 flex items-end">
                <div className="input-container flex-1">
                  <label htmlFor="#" className="mb-3 block">
                    Search
                  </label>
                  <div className="input-wrapper flex items-center flex-1 rounded-lg px-6">
                    <img src={search} alt="" />
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search"
                      className="flex-1 h-full bg-transparent border-0 outline-none ml-2"
                    />
                    <img src={arrowdown} alt="" />
                  </div>
                </div>
                <button className="rounded-lg px-5" onClick={resetFilter}>Reset Filter</button>
              </div>

              <div className="table-wrapper">
                <SubAdminTable refetchData={refetch} data={subAdmins} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
