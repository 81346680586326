export const AgentReportUtil = [
  {
    id: 112,
    agentname: "agent_one",
    currency: "PHP",
    bet: "0.00",
    betphp: "0.00",
    win: "0.00",
    winphp: "0.00",
    ggr: "0.00",
    bonus: "0.00",
    bonusphp: "0.00",
    comission: "0.00",
    revenue: "0.00",
  },
  {
    id: 112,
    agentname: "agent_one",
    currency: "PHP",
    bet: "0.00",
    betphp: "0.00",
    win: "0.00",
    winphp: "0.00",
    ggr: "0.00",
    bonus: "0.00",
    bonusphp: "0.00",
    comission: "0.00",
    revenue: "0.00",
  },
  {
    id: 112,
    agentname: "agent_one",
    currency: "PHP",
    bet: "0.00",
    betphp: "0.00",
    win: "0.00",
    winphp: "0.00",
    ggr: "0.00",
    bonus: "0.00",
    bonusphp: "0.00",
    comission: "0.00",
    revenue: "0.00",
  },
  {
    id: 112,
    agentname: "agent_one",
    currency: "PHP",
    bet: "0.00",
    betphp: "0.00",
    win: "0.00",
    winphp: "0.00",
    ggr: "0.00",
    bonus: "0.00",
    bonusphp: "0.00",
    comission: "0.00",
    revenue: "0.00",
  },
  {
    id: 112,
    agentname: "agent_one",
    currency: "PHP",
    bet: "0.00",
    betphp: "0.00",
    win: "0.00",
    winphp: "0.00",
    ggr: "0.00",
    bonus: "0.00",
    bonusphp: "0.00",
    comission: "0.00",
    revenue: "0.00",
  },
  {
    id: 112,
    agentname: "agent_one",
    currency: "PHP",
    bet: "0.00",
    betphp: "0.00",
    win: "0.00",
    winphp: "0.00",
    ggr: "0.00",
    bonus: "0.00",
    bonusphp: "0.00",
    comission: "0.00",
    revenue: "0.00",
  },
  {
    id: 112,
    agentname: "agent_one",
    currency: "PHP",
    bet: "0.00",
    betphp: "0.00",
    win: "0.00",
    winphp: "0.00",
    ggr: "0.00",
    bonus: "0.00",
    bonusphp: "0.00",
    comission: "0.00",
    revenue: "0.00",
  },
];
