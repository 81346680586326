export const ConfigureTable = ({ data }) => {
  return (
    <>
      <table className="w-full AgentTable">
        <thead>
          <th>Name</th>
          <th>Value</th>
        </thead>
        <tbody>
          {data.map((EachData) => (
            <tr>
              <td>{EachData.key}</td>

              <td>{EachData.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
