import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { privateRequest } from "../../config/axios.config";
import { dateFormatter } from "../../utils";
import { Alert } from "./Alert";

export const PendingWithdrawlTable = ({ data, refetch }) => {
  const querClient = useQueryClient();
  const [transactionId, setTransactionId] = useState(null);
  const [reason, setReason] = useState(null);
  const [approvePopup, setApprovePopup] = useState(false);
  const [delpopup, setdelpopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const approveWithdrawal = useMutation(
    async (transactionId) => {
      try {
        setLoading(true);
        const res = await privateRequest.get(
          `admin/withdrawal/approved-withdrawal-request?requestId=${transactionId}`
        );

        // Check if the response indicates an error
        if (res.data && res.data.success === false) {
          throw new Error(res.data.message);
        }
        setLoading(false);
        // If the request is successful, return the data
        return res?.data;
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: (data) => {
        console.log("Request approved successfully:", data);
        refetch();
      },
      onError: (error) => {
        console.error("Error in approveWithdrawal mutation:", error);

        // Display an appropriate error message in the toast
        const errorMessage = error?.message || "Error approving withdrawal";
        toast.error(errorMessage);
      },
    }
  );
  const declineWithdrawal = useMutation(
    (payload) =>
      privateRequest.post(
        `admin/withdrawal/decline-withdrawal-request`,
        payload
      ),
    refetch()
  );

  const handelApproval = (id) => {
    setApprovePopup(true);
    setTransactionId(id);
  };

  const handelDelete = (id) => {
    setdelpopup(true);
    setTransactionId(id);
  };

  const submitApprove = async () => {
    try {
      toast.promise(approveWithdrawal.mutateAsync(transactionId), {
        pending: "Approving withdrawal...",
        success: "Withdrawal approved successfully!",
        error: "Error during withdrawal approval",
      });
    } catch (error) {
      console.error("Error in submitApprove:", error);
      toast.error("Error during withdrawal approval");
    }
  };

  const submitDecline = async () => {
    const payload = {
      requestId: transactionId,
      reason: reason,
    };
    try {
      toast.promise(declineWithdrawal.mutateAsync(payload), {
        pending: "Declining withdrawal...",
        success: () => {
          querClient.invalidateQueries("withdrawals");
          return "Withdrawal declined successfully!";
        },
        error: "Error decline withdrawal",
      });
    } catch (error) {
      console.log(error);
    }
  };

  console.log(loading);

  return (
    <>
      {approvePopup && (
        <Alert
          setactive={() => setApprovePopup(false)}
          confirmSubadminBlock={(value) => {
            if (value) {
              submitApprove();
              setApprovePopup(false);
            }
          }}
          title="Confirm Withdrawal Approval"
          text="You are about to approve a withdrawal request. This will initiate the transfer of funds. Please confirm to proceed with the approval."
          buttonText="Approve"
        />
      )}
      {delpopup && (
        <Alert
          confirmReason={(value) => {
            setReason(value);
          }}
          needReason={true}
          setactive={() => setdelpopup(false)}
          confirmSubadminBlock={(value) => {
            if (value) {
              submitDecline();
              setdelpopup(false);
            }
          }}
          title="Confirm Withdrawal Decline"
          text="This action cannot be undone. Please confirm that you wish to decline the withdrawal request and provide a reason to ensure clear communication with the user."
          buttonText="Decline"
        />
      )}
      <table className="w-full AgentTable">
        <thead>
          <th>TXID</th>
          <th>Ref No</th>
          <th>Timestamp</th>
          <th>User </th>
          <th>Payment Method </th>

          <th>Actual Amount($)</th>
          <th>Payment Currency Amount</th>

          <th>Note</th>
          <th>Status</th>
          <th>Actions</th>
        </thead>
        <tbody>
          {data?.map((row) => (
            <tr>
              <td>{row?.transactionReference}</td>
              <td>{row?.meta?.refno ? <>{row?.meta?.refno}</> : <>-</>}</td>

              <td>{dateFormatter(row?.createdAt)}</td>
              <td>{row?.userId?.email}</td>
              <td>{row?.meta?.paymentMethod}</td>

              <td
                style={{
                  color: row?.transactionType === "debit" ? "red" : "green",
                }}
              >
                {row?.transactionType === "debit" && "- "}
                {row?.transactionType === "credit" && "+ "}
                {row?.amount?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td>
                {parseFloat(row?.meta?.paymentCurrencyAmount)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                {row?.meta?.paymentCurrency?.toUpperCase()}
              </td>

              <td>{row?.note}</td>
              <td className="px-3">{row?.status?.toUpperCase()}</td>
              <td>
                <div className="flex items-center gap-2">
                  <Link
                    className="mr-1 bg-[#6FCF17] text-white px-3 py-1 rounded-sm"
                    to={`/admin/withdrawls/details/${row?._id}`}
                  >
                    View
                  </Link>
                  {row?.status === "pending" && (
                    <>
                      <button
                        disabled={loading}
                        onClick={() => handelApproval(row?._id)}
                        className="mr-1 bg-[#6FCF17] text-white px-3 py-1 rounded-sm"
                      >
                        Approve
                      </button>
                      <button
                        disabled={loading}
                        onClick={() => handelDelete(row?._id)}
                        className="mr-1 bg-[#fd3939] text-white px-3 py-1 rounded-sm"
                      >
                        Decline
                      </button>
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* <div className="flex items-center mt-6">
        <CheckboxCustom type="checkbox" id="report-withdrawals" />
        <p className="ml-2 checkbox-para">Crypto report for withdrawals</p>
      </div> */}

      {/* <div className="flex pagination-table items-center justify-center mt-8">
        <button className="flex items-center mr-2">
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.77195 2.334C8.81435 2.29046 8.86504 2.25585 8.92103 2.23222C8.97702 2.20859 9.03718 2.19641 9.09795 2.19641C9.15872 2.19641 9.21888 2.20859 9.27487 2.23222C9.33087 2.25585 9.38155 2.29046 9.42395 2.334C9.51087 2.42325 9.55952 2.54292 9.55952 2.6675C9.55952 2.79209 9.51087 2.91175 9.42395 3.001L4.55095 7.999L9.42395 12.998C9.46782 13.0429 9.50241 13.096 9.52574 13.1543C9.54908 13.2126 9.56071 13.2749 9.55997 13.3377C9.55923 13.4004 9.54613 13.4624 9.52142 13.5202C9.4967 13.5779 9.46087 13.6301 9.41595 13.674C9.37317 13.7158 9.32256 13.7488 9.26702 13.771C9.21148 13.7932 9.15211 13.8043 9.09229 13.8035C9.03248 13.8028 8.97339 13.7903 8.91843 13.7667C8.86346 13.7431 8.81368 13.7089 8.77195 13.666L3.58995 8.348C3.49894 8.25463 3.448 8.12939 3.448 7.999C3.448 7.86861 3.49894 7.74337 3.58995 7.65L8.77195 2.334ZM12.772 2.334C12.8143 2.29046 12.865 2.25585 12.921 2.23222C12.977 2.20859 13.0372 2.19641 13.098 2.19641C13.1587 2.19641 13.2189 2.20859 13.2749 2.23222C13.3309 2.25585 13.3816 2.29046 13.424 2.334C13.5109 2.42325 13.5595 2.54292 13.5595 2.6675C13.5595 2.79209 13.5109 2.91175 13.424 3.001L8.55095 7.999L13.424 12.998C13.4678 13.0429 13.5024 13.096 13.5257 13.1543C13.5491 13.2126 13.5607 13.2749 13.56 13.3377C13.5592 13.4004 13.5461 13.4624 13.5214 13.5202C13.4967 13.5779 13.4609 13.6301 13.416 13.674C13.3732 13.7158 13.3226 13.7488 13.267 13.771C13.2115 13.7932 13.1521 13.8043 13.0923 13.8035C13.0325 13.8028 12.9734 13.7903 12.9184 13.7667C12.8635 13.7431 12.8137 13.7089 12.772 13.666L7.58995 8.348C7.49894 8.25463 7.448 8.12939 7.448 7.999C7.448 7.86861 7.49894 7.74337 7.58995 7.65L12.772 2.334Z"
              fill="#959699"
            />
          </svg>

          <p>Previous</p>
        </button>
        <button className="rounded count active">1</button>
        <button className="rounded count">2</button>
        <button className="rounded count">3</button>
        <button className="rounded count">...</button>
        <button className="rounded count">15</button>
        <button className="flex active items-center ml-2">
          <p>Next</p>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.22805 2.334C8.18565 2.29046 8.13496 2.25585 8.07897 2.23222C8.02298 2.20859 7.96282 2.19641 7.90205 2.19641C7.84128 2.19641 7.78112 2.20859 7.72513 2.23222C7.66913 2.25585 7.61845 2.29046 7.57605 2.334C7.48913 2.42325 7.44048 2.54292 7.44048 2.6675C7.44048 2.79209 7.48913 2.91175 7.57605 3.001L12.4491 7.999L7.57605 12.998C7.53218 13.0429 7.49759 13.096 7.47426 13.1543C7.45092 13.2126 7.43929 13.2749 7.44003 13.3377C7.44077 13.4004 7.45387 13.4624 7.47858 13.5202C7.5033 13.5779 7.53913 13.6301 7.58405 13.674C7.62683 13.7158 7.67744 13.7488 7.73298 13.771C7.78852 13.7932 7.84789 13.8043 7.90771 13.8035C7.96752 13.8028 8.02661 13.7903 8.08157 13.7667C8.13654 13.7431 8.18632 13.7089 8.22805 13.666L13.4101 8.348C13.5011 8.25463 13.552 8.12939 13.552 7.999C13.552 7.86861 13.5011 7.74337 13.4101 7.65L8.22805 2.334ZM4.22805 2.334C4.18565 2.29046 4.13496 2.25585 4.07897 2.23222C4.02298 2.20859 3.96282 2.19641 3.90205 2.19641C3.84128 2.19641 3.78112 2.20859 3.72513 2.23222C3.66913 2.25585 3.61844 2.29046 3.57605 2.334C3.48913 2.42325 3.44048 2.54292 3.44048 2.6675C3.44048 2.79209 3.48913 2.91175 3.57605 3.001L8.44905 7.999L3.57605 12.998C3.53218 13.0429 3.49759 13.096 3.47425 13.1543C3.45092 13.2126 3.43929 13.2749 3.44003 13.3377C3.44077 13.4004 3.45387 13.4624 3.47858 13.5202C3.50329 13.5779 3.53913 13.6301 3.58405 13.674C3.62683 13.7158 3.67744 13.7488 3.73298 13.771C3.78852 13.7932 3.84789 13.8043 3.90771 13.8035C3.96752 13.8028 4.02661 13.7903 4.08157 13.7667C4.13654 13.7431 4.18632 13.7089 4.22805 13.666L9.41005 8.348C9.50106 8.25463 9.552 8.12939 9.552 7.999C9.552 7.86861 9.50106 7.74337 9.41005 7.65L4.22805 2.334Z"
              fill="#959699"
            />
          </svg>
        </button>
      </div> */}
    </>
  );
};
